<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 ">
                <h4 class="title-in-h">{{info_page.header}}</h4>
                <breadcrumb>
                    <breadcrumb-item v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
                        <router-link :to="{path:breadcrumb.path}" class="color-blue-var">{{breadcrumb.name}}</router-link>
                    </breadcrumb-item>
                    <breadcrumb-item active v-else>
                        {{breadcrumb.name}}
                    </breadcrumb-item>
                </breadcrumb>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <card  v-loading="screenLoading"
                       element-loading-lock="true"
                       :element-loading-text="screenText"
                       element-loading-customClass="loading-large"
                       element-loading-spinner="el-icon-loading">
                    <div>
                        <div class="col-sm-12">
                            <div class="box-inventory promotions">
                                <div class="form-updates w-border">
                                    <l-switch v-model="promotion_info.visible.min_nights" type="primary" on-text="On"
                                              off-text="Off" @input="validadateForm"></l-switch>
                                    <div class="row">
                                        <div class="col-md-12 pl-0">
                                            <div class="head  mt-2">
                                                <label class="subtitle">{{$t('promotion.form.minimun_nights.title')}}</label>
                                            </div>
                                        </div>
                                        <div class="col-md-10" v-show="promotion_info.visible.min_nights">

                                            <div class="group-item">
                                                <label class="mb-1 mt-2">{{$t('promotion.form.minimun_nights.min')}}</label>
                                                <fg-input type="text"
                                                          @input="validadateForm"
                                                          class="input-default"
                                                          placeholder=""
                                                          v-model="promotion.min_nights"
                                                          :class="(promotion_info.visible.min_nights && activeErrors && (promotion.min_nights<1 || promotion.min_nights===null))?'has-error':''"
                                                >
                                                </fg-input>
                                                <span class="btn-link ml-3" @click="addElement('min_nights'), validadateForm()" v-if="!promotion.max_nights_status">
                                                        {{$t('promotion.form.minimun_nights.add')}}
                                                    </span>
                                            </div>
                                            <div class="group-item" v-if="promotion.max_nights_status">
                                                <label>{{$t('promotion.form.minimun_nights.max')}}</label>
                                                <fg-input type="text"
                                                          @input="validadateForm"
                                                          class="input-default"
                                                          placeholder=""
                                                          v-model="promotion.max_nights"
                                                          :class="promotion_info.visible.min_nights && promotion.max_nights_status && activeErrors
                                                           && (promotion.max_nights<2  || parseInt(promotion.max_nights)<=parseInt(promotion.min_nights))?'has-error':''">
                                                </fg-input>
                                                <span class="btn-link ml-3" @click="deleteElement('min_nights'), validadateForm()">
                                                    {{$t('promotion.form.minimun_nights.delete')}}
                                                </span>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div class="form-updates w-border">
                                    <l-switch v-model="promotion_info.visible.advance_options" type="primary"
                                              on-text="On" off-text="Off"  @input="validadateForm"></l-switch>
                                    <div class="row">
                                        <div class="col-md-12 pl-0">
                                            <div class="head  mt-2">
                                                <label class="subtitle">{{$t('promotion.form.days_advaces.title')}}</label>
                                            </div>
                                        </div>
                                        <div class="col-md-10" v-show="promotion_info.visible.advance_options">

                                            <div class="form-group" >
                                                <fg-input  v-model="promotion.option_cutoff"  >
                                                    <radio label="0" v-model="promotion.option_cutoff"  value="0" @input="validadateForm">
                                                        {{$t('promotion.form.days_advaces.advance')}}
                                                    </radio>
                                                    <radio label="1" v-model="promotion.option_cutoff"  value="1" @input="validadateForm">
                                                        {{$t('promotion.form.days_advaces.today')}}
                                                    </radio>
                                                </fg-input>
                                            </div>


                                            <div class="group-item" v-show="promotion.option_cutoff === '0'">
                                                <label class="mb-1 mt-3">{{$t('promotion.form.days_advaces.min')}}</label>
                                                <fg-input type="text"
                                                          @input="validadateForm"
                                                          class="input-default"
                                                          placeholder=""
                                                          v-model="promotion.min_cutoff"
                                                          :class="(promotion_info.visible.advance_options && activeErrors && promotion.option_cutoff==='0' && (promotion.min_cutoff<1 || promotion.min_cutoff===null))?'has-error':''"
                                                >
                                                </fg-input>
                                                <span class="ml-3">{{$t('promotion.form.days_advaces.days_text')}}</span>
                                                <span class="btn-link  ml-3" v-show="!promotion.max_cutoff_status && promotion.option_cutoff === '0'"
                                                      @click="promotion.max_cutoff_status = true,  validadateForm()">
                                                        {{$t('promotion.form.days_advaces.add')}}
                                                    </span>
                                            </div>
                                            <div class="group-item"
                                                 v-show="promotion.max_cutoff_status && promotion.option_cutoff === '0'">
                                                <label >{{$t('promotion.form.days_advaces.max')}}</label>
                                                <fg-input type="text"
                                                          @input="validadateForm"
                                                          class="input-default"
                                                          placeholder=""
                                                          v-model="promotion.max_cutoff"
                                                          :class="(promotion_info.visible.advance_options && activeErrors && promotion.max_cutoff_status && promotion.option_cutoff==='0'
                                                          && (promotion.max_cutoff<2 || promotion.max_cutoff===null || parseInt(promotion.max_cutoff)<=parseInt(promotion.min_cutoff)))?'has-error':''"

                                                >
                                                </fg-input>
                                                <span class="ml-3">{{$t('promotion.form.days_advaces.days_text')}}</span>
                                                <span class="btn-link  ml-3" @click="deleteElement('advance_options'), validadateForm()">
                                                    {{$t('promotion.form.days_advaces.delete')}}
                                                </span>
                                            </div>


                                            <div class="group-item mt-3" v-show="promotion.option_cutoff === '1'" >
                                                <label  class="mb-1 mt-2">{{$t('promotion.form.days_advaces.can_reserve')}}</label>


                                                <fg-input style="width: 300px" class="input-default"
                                                          :class="(promotion_info.visible.advance_options && activeErrors && promotion.option_cutoff==='1' && (promotion.booking_time === undefined ||
                                                          promotion.booking_time===null ||  promotion.booking_time[0]==='' || promotion.booking_time[1]==='' || promotion.booking_time[0]>=promotion.booking_time[1]))?'has-error':''">
                                                            <el-time-picker
                                                                    is-range
                                                                    @input="validadateForm()"
                                                                    format="HH:mm"
                                                                    v-model="promotion.booking_time"
                                                                    range-separator="a"
                                                                    start-placeholder="Inicio"
                                                                    end-placeholder="Final">
                                                            </el-time-picker>
                                                </fg-input>


                                                <!--<fg-input style="max-width: 100px" class="input-default"
                                                          :class="(promotion_info.visible.advance_options && activeErrors && promotion.option_cutoff==='1' &&
                                                            (promotion.booking_time_from === undefined || promotion.booking_time_from===null ||
                                                            promotion.booking_time_from==='' || promotion.booking_time_from===''))?'has-error':''">
                                                    <el-time-select
                                                            @input="validadateForm()"
                                                            v-model="promotion.booking_time_from"
                                                            :picker-options="{
                                                                      start: '00:00',
                                                                      step: '00:15',
                                                                      end: '23:59',
                                                                      maxTime: promotion.booking_time_to
                                                                    }"
                                                            placeholder="Inicio"


                                                    >
                                                    </el-time-select>
                                                </fg-input>
                                                a
                                                <fg-input style="max-width: 100px" class="input-default"  @input="validadateForm()"
                                                          :class="(promotion_info.visible.advance_options && activeErrors && promotion.option_cutoff==='1' &&
                                                            (promotion.booking_time_to === undefined || promotion.booking_time_to===null ||
                                                            promotion.booking_time_to==='' || promotion.booking_time_to===''))?'has-error':''">
                                                    <el-time-select
                                                            @input="validadateForm()"
                                                            v-model="promotion.booking_time_to"
                                                            :picker-options="{
                                                                      start: '00:00',
                                                                      step: '00:15',
                                                                      end: '23:59',
                                                                      minTime: promotion.booking_time_from
                                                                    }"
                                                            placeholder="Final"
                                                             >
                                                    </el-time-select>
                                                </fg-input>-->
                                                <span class="ml-3">{{$t('promotion.form.days_advaces.text_hour')}}</span>



                                                <label class="full-width">
                                                    {{$t('promotion.form.days_advaces.info_show')}}
                                                    {{(promotion.booking_time && promotion.booking_time!==null && promotion.booking_time.length)?formatConverte(promotion.booking_time[0], 'time')+' Hrs':'--:--'}}
                                                    {{$t('promotion.form.days_advaces.info_show_end')}}</label>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div class="form-updates w-border">
                                    <l-switch v-model="promotion.free_night_active" type="primary" on-text="On"
                                              off-text="Off"  @input="validadateForm"></l-switch>
                                    <div class="row">
                                        <div class="col-md-12 pl-0">
                                            <div class="head  mt-2">
                                                <label class="subtitle"> {{$t('promotion.form.night_free.title')}}</label>
                                            </div>
                                        </div>
                                        <div class="col-md-10" v-show="promotion.free_night_active">
                                            <div class="group-item" >
                                                <label class="fllw mb-1 mt-2">{{$t('promotion.form.night_free.question_1')}}</label>
                                                <fg-input  class="input-default large-size" v-model="promotion.free_night"
                                                           :class="promotion.free_night_active && activeErrors && promotion.free_night===null?'has-error':''">
                                                    <el-select class="select-default large-size" size="large" v-model="promotion.free_night" @change="validadateForm()">
                                                        <el-option v-for="option in promotion_info.options.discount_apply" class="select-default" :value="option.value"
                                                                   :label="option.name" >
                                                        </el-option>
                                                    </el-select>
                                                </fg-input>
                                                <div class="form-check">
                                                    <label for="limit_one" class="form-check-label check-admin">
                                                        <input class="form-check-input" type="checkbox" id="limit_one" :value="promotion.free_night_limit_to_stay"  v-model="promotion.free_night_limit_to_stay"  @change="stepsValidate('type')">
                                                        <span class="form-check-sign" ></span>
                                                        <span>{{$t('promotion.form.promotion.limit_one')}}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-updates w-border"  v-if="info_page.children">
                                    <l-switch v-model="promotion.free_children_active" type="primary" on-text="On"
                                              off-text="Off"></l-switch>
                                    <div class="row">
                                        <div class="col-md-12 pl-0">
                                            <div class="head  mt-2">
                                                <label class="subtitle"> {{$t('promotion.form.night_free.children_title')}}</label>
                                            </div>
                                        </div>
                                        <div class="col-md-10" v-show="promotion.free_children_active">
                                            <div class="group-item">
                                                <fg-input  v-model="promotion.free_children_type" >
                                                    <radio label="1" v-model="promotion.free_children_type" value="1" ><span>{{$t('promotion.form.night_free.for_stay')}}</span>
                                                    </radio>

                                                    <radio label="2" v-model="promotion.free_children_type"  value="2" ><span>{{$t('promotion.form.night_free.for_night')}}</span>
                                                    </radio>
                                                </fg-input>

                                                   <div class="group-inside">
                                                       <label class="fllw mb-1">{{$t('promotion.form.night_free.children_qty')}}</label>
                                                       <fg-input  class="input-default large-size" v-model="promotion.free_children">
                                                           <el-select class="select-default large-size" size="large" v-model="promotion.free_children">
                                                               <el-option v-for="numberVar in 7" class="select-default" :value="numberVar"
                                                                          :label="numberVar" >
                                                               </el-option>
                                                           </el-select>
                                                       </fg-input>

                                                       <label class="fllw mb-1" v-if="promotion.free_children_type==='1'">{{$t('promotion.form.night_free.children_night')}}</label>
                                                       <fg-input  class="input-default large-size" v-model="promotion.free_children_night" v-if="promotion.free_children_type==='1'"
                                                                  :class="promotion.free_children_active && activeErrors && promotion.free_children_night===null?'has-error':''">
                                                           <el-select class="select-default large-size" size="large" v-model="promotion.free_children_night" >
                                                               <el-option v-for="option in promotion_info.options.discount_apply" class="select-default" :value="option.value"
                                                                          :label="option.name" >
                                                               </el-option>
                                                           </el-select>
                                                       </fg-input>
                                                   </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-updates w-border"   v-if="info_page.jrs">
                                    <div class="bg-transparend-modal" v-if="!jrs_block.active" @click="alertError('Advertencia', 'Es necesario activar un contrato Todo Incluido para continuar')"></div>
                                    <l-switch v-model="promotion.free_jrs_active" type="primary" on-text="On"
                                              off-text="Off"></l-switch>
                                    <div class="row">
                                        <div class="col-md-12 pl-0">
                                            <div class="head  mt-2">
                                                <label class="subtitle"> {{$t('promotion.form.night_free.jrs_title')}}</label>
                                            </div>
                                        </div>
                                        <div class="col-md-10" v-show="promotion.free_jrs_active">
                                            <div class="group-item">
                                                <fg-input  v-model="promotion.free_jrs_type" >
                                                    <radio label="1" v-model="promotion.free_jrs_type" value="1" ><span>{{$t('promotion.form.night_free.for_stay')}}</span>
                                                    </radio>

                                                    <radio label="2" v-model="promotion.free_jrs_type"  value="2" ><span>{{$t('promotion.form.night_free.for_night')}}</span>
                                                    </radio>
                                                </fg-input>

                                                <div class="group-inside">
                                                    <label class="fllw mb-1">{{$t('promotion.form.night_free.jrs_qty')}}</label>
                                                    <fg-input  class="input-default large-size" v-model="promotion.free_jrs">
                                                        <el-select class="select-default large-size" size="large" v-model="promotion.free_jrs">
                                                            <el-option v-for="numberVar in 7" class="select-default" :value="numberVar"
                                                                       :label="numberVar" >
                                                            </el-option>
                                                        </el-select>
                                                    </fg-input>

                                                    <label class="fllw mb-1" v-if="promotion.free_jrs_type==='1'">{{$t('promotion.form.night_free.jrs_night')}}</label>
                                                    <fg-input  class="input-default large-size" v-model="promotion.free_jrs_night" v-if="promotion.free_jrs_type==='1'"
                                                               :class="promotion.free_jrs_active && activeErrors && promotion.free_jrs_night===null?'has-error':''">
                                                        <el-select class="select-default large-size" size="large" v-model="promotion.free_jrs_night" >
                                                            <el-option v-for="option in promotion_info.options.discount_apply" class="select-default" :value="option.value"
                                                                       :label="option.name" >
                                                            </el-option>
                                                        </el-select>
                                                    </fg-input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div class="form-updates w-border" >
                                    <l-switch v-model="promotion.aggregated_active" type="primary" on-text="On"
                                              off-text="Off" @input="validadateForm()"></l-switch>
                                    <div class="row">
                                        <div class="col-md-12 pl-0">
                                            <div class="head  mt-2 mb-3">
                                                <label class="subtitle"> {{$t('promotion.form.night_free.aggregates')}}</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12" v-show="promotion.aggregated_active">
                                            <div class="group-item title">
                                                <label >{{$t('promotion.form.night_free.aggregates')}}</label>
                                                <label class="ml-3" >{{$t('promotion.form.night_free.spanish')}}</label>
                                                <label class="ml-3">{{$t('promotion.form.night_free.english')}}</label>
                                            </div>
                                            <div class="group-item" v-for="(aggregate, aggre_idx) in promotion.aggregated_values">

                                                <div class="group-inside">
                                                    <fg-input  class="input-default large-size" v-model="aggregate.name" style="vertical-align: top;"
                                                               :class="promotion.aggregated_active && activeErrors && aggregate.name===''?'has-error':''"
                                                               >
                                                        <el-select class="select-default large-size" size="large" v-model="aggregate.name" @change="validadateForm()">
                                                            <el-option v-for="item in promotion_info.options.aggregates" class="select-default" :value="item.value"
                                                                       :label="item.name" >
                                                            </el-option>
                                                        </el-select>
                                                    </fg-input>

                                                    <el-input type="textarea"
                                                              style="width: 200px"
                                                              class="input-default ml-3"
                                                              placeholder=""
                                                              rows="4"
                                                              resize="none"
                                                              v-model="aggregate.text_es"
                                                              @input="validadateForm()"
                                                              :class="promotion.aggregated_active && activeErrors && aggregate.text_es===''?'has-error':''"
                                                    >
                                                    </el-input>
                                                        <el-input type="textarea"
                                                                  style="width: 200px"
                                                                  class="input-default ml-3"
                                                                  placeholder=""
                                                                  rows="4"
                                                                  resize="none"
                                                                  v-model="aggregate.text_en"
                                                                  @input="validadateForm()"
                                                                  :class="promotion.aggregated_active && activeErrors && aggregate.text_en===''?'has-error':''"
                                                        >
                                                    </el-input>
                                                    <a class="minus-aggreates ml-3"  @click="deleteAggregated(aggre_idx), validadateForm()"><i class="fa fa-minus"></i></a>
                                                </div>
                                            </div>
                                            <span class="btn-link ml-3 mt-3 d-block"
                                                  :class="promotion.aggregated_active && promotion.aggregated_values.length === 0 && activeErrors?'text-danger':''"
                                                  @click="addElement('add_aggregates'), validadateForm()">
                                                        + {{$t('promotion.form.night_free.add_aggregates')}}
                                                    </span>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-updates w-border">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="head  mt-3">
                                                <label class="subtitle">{{$t('promotion.form.promotion.title')}}</label>
                                            </div>
                                            <div class="group-edit" v-show="info_page.index_page>1">
                                                <a class="a-right r-20 type-button" @click="info_page.index_page=1"> <i
                                                        class="fa fa-check"></i></a>
                                                <a class="a-right type-text" @click="info_page.index_page=1"> Editar</a>
                                            </div>
                                            <div class="resumen-item" v-show="info_page.index_page>1">

                                                <span v-show="promotion.type==='0'">{{promotion.value+' '+promotion.discount_type+' descuento'}}</span>

                                                <div v-show="promotion.type==='1'" style="display: grid">
                                                    <span v-for="(valueDay, idxK) in promotion.value_days" class="fllw"  :class="idxK===0?'ordL':'ordF'">{{ dayName(idxK)+' '+ valueDay+' %'+' descuento'}}</span>
                                                </div>

                                                <div v-show="promotion.type==='2'">
                                                    <span v-show="promotion.discount_type==='1'">Los huéspedes recibirán gratis la {{nightDiscount(promotion.value)}} noche de su estancia.</span>
                                                    <span v-show="promotion.discount_type==='2'">Los huéspedes recibirán un {{promotion.discount_night}}% de descuento en la {{nightDiscount(promotion.value)}} noche de su estancia.</span>
                                                    <span v-show="promotion.limit_to_stay" class="fllw">Este descuento sólo se aplicará una vez por reserva.</span>
                                                </div>




                                                <span class="fllw" v-show="promotion.all_rate_configs">{{'Aplica para '+$t('promotion.form.promotion.apply_legend')}}</span>


                                                <div class="box-rates" v-show="!promotion.all_rate_configs">
                                                    <span class="fllw mb-0">Aplica para:</span>
                                                    <div v-for="rateInfo in promotion_info.rates" v-show="roomShow(rateInfo)">
                                                        <label class="fw mb-0 mt-2">{{rateInfo.name}}</label>
                                                        <div class="form-check mb-0 pl-3"  v-for="rateplan in rateInfo.rateplans">
                                                            <span v-show="rateplan.status">
                                                                {{rateplan.name}}
                                                            </span>
                                                            <div class="pl-3 mb-0"  v-for="rateLink in  rateplan.rateplansLink">
                                                                <span v-show="rateLink.status">
                                                                    {{rateLink.name}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                        <div class="col-md-12" v-show="info_page.index_page===1">

                                            <div class="form-check">
                                                <label>
                                                    {{$t('promotion.form.promotion.type_question')}}
                                                </label>
                                                <fg-input  v-model="promotion.type"  >
                                                    <radio label="0" v-model="promotion.type" value="0" @input="clearForm"><span >{{$t('promotion.form.promotion.unique')}}</span>
                                                        <span class="fllw">{{$t('promotion.form.promotion.unique_description')}}</span>
                                                    </radio>

                                                    <radio label="1" v-model="promotion.type"  value="1" @input="clearForm"><span >{{$t('promotion.form.promotion.each_day')}}</span>
                                                        <span class="fllw">{{$t('promotion.form.promotion.each_description')}}</span>
                                                    </radio>


                                                </fg-input>
                                            </div>


                                            <div class="group-item" v-if="promotion.type === '0'">
                                                <label style="width: 150px">{{$t('promotion.form.promotion.type.title')}}</label>
                                                <label class="ml-3" style="width: 150px">{{$t('promotion.form.promotion.type.discount')}}</label><br>
                                                <fg-input v-model="promotion.discount_type" class="input-default">
                                                    <el-select class="select-default" size="large"
                                                               v-model="promotion.discount_type"
                                                               @change="stepsValidate('type')">
                                                        <el-option v-for="option in promotion_info.options.type"
                                                                   class="select-default" :value="option.value"
                                                                   :label="option.name">
                                                        </el-option>
                                                    </el-select>
                                                </fg-input>
                                                <fg-input type="text"
                                                          class="input-default ml-3"
                                                          placeholder=""
                                                          v-model="promotion.value" @input="stepsValidate('type'), promotion.value = toFixed(promotion.value)" @keypress="isNumberKey($event)">
                                                </fg-input>
                                                <span class="ml-3">{{$t('promotion.form.promotion.type.description')}}</span>
                                                <span class="fllw">{{$t('promotion.form.promotion.type.legend')}}</span>


                                            </div>


                                            <div class="group-item mt-3" v-show="promotion.type === '1'"  :key="stepsValidate('type')">
                                                <div class="week-form lw-head">
                                                    <div class="item-7" data-key="01">
                                                        <span>Lun</span>
                                                        <fg-input type="text"
                                                                  v-model="promotion.value_days[1]"  @input="stepsValidate('type'), promotion.value_days[1] = toFixed(promotion.value_days[1])" @keypress="isNumberKey($event)"></fg-input>
                                                        <span class="fa fa-percent"></span>
                                                    </div>
                                                    <div class="item-7" data-key="02">
                                                        <span>Mar</span>
                                                        <fg-input type="text"
                                                                  v-model="promotion.value_days[2]" @input="stepsValidate('type'), promotion.value_days[2] = toFixed(promotion.value_days[2])" @keypress="isNumberKey($event)"></fg-input>
                                                        <span class="fa fa-percent"></span>
                                                    </div>
                                                    <div class="item-7" data-key="03">
                                                        <span>Mie</span>
                                                        <fg-input type="text"
                                                                  v-model="promotion.value_days[3]" @input="stepsValidate('type'), promotion.value_days[3] = toFixed(promotion.value_days[3])" @keypress="isNumberKey($event)"></fg-input>
                                                        <span class="fa fa-percent"></span>
                                                    </div>
                                                    <div class="item-7" data-key="04">
                                                        <span>Jue</span>
                                                        <fg-input type="text"
                                                                  v-model="promotion.value_days[4]" @input="stepsValidate('type'), promotion.value_days[4] = toFixed(promotion.value_days[4])" @keypress="isNumberKey($event)"></fg-input>
                                                        <span class="fa fa-percent"></span>
                                                    </div>
                                                    <div class="item-7" data-key="05">
                                                        <span>Vie</span>
                                                        <fg-input type="text"
                                                                  v-model="promotion.value_days[5]" @input="stepsValidate('type'), promotion.value_days[5] = toFixed(promotion.value_days[5])" @keypress="isNumberKey($event)"></fg-input>
                                                        <span class="fa fa-percent"></span>
                                                    </div>
                                                    <div class="item-7" data-key="06">
                                                        <span>Sab</span>
                                                        <fg-input type="text"
                                                                  v-model="promotion.value_days[6]" @input="stepsValidate('type'), promotion.value_days[6] = toFixed(promotion.value_days[6])" @keypress="isNumberKey($event)"></fg-input>
                                                        <span class="fa fa-percent"></span>
                                                    </div>
                                                    <div class="item-7" data-key="07">
                                                        <span>Dom</span>
                                                        <fg-input type="text"
                                                                  v-model="promotion.value_days[0]" @input="stepsValidate('type'), promotion.value_days[0] = toFixed(promotion.value_days[0])" @keypress="isNumberKey($event)"></fg-input>
                                                        <span class="fa fa-percent"></span>
                                                    </div>
                                                </div>
                                                <span class="legend-tip" v-show="promotion.type==='0'">{{$t('promotion.form.promotion.type.legend')}}</span>
                                            </div>

                                            <div class="group-item">
                                                 <span class="btn-link mt-4"
                                                       @click="addElement('type')">
                                                     {{$t('promotion.form.promotion.add')}}
                                                 </span>
                                                <div v-show="promotion.max_reservations_status">
                                                    <label >{{$t('promotion.form.promotion.limit')}}</label>
                                                    <fg-input type="text"
                                                              class="input-default"
                                                              placeholder=""
                                                              v-model="promotion.max_reservations"
                                                              @input="stepsValidate('type')"

                                                    >
                                                    </fg-input>
                                                    <span class="ml-3">{{$t('promotion.form.promotion.room_days')}}</span>
                                                    <span class="fa fa-minus ml-3" @click="deleteElement('type')">
                                                 </span>
                                                </div>
                                            </div>

                                            <div class="group-item mt-3">
                                                <label class="fw fllw">{{$t('promotion.form.promotion.apply')}}</label>


                                                <div class="form-check  mb-0">
                                                    <label for="allRates" class="form-check-label">
                                                        <input class="form-check-input" type="checkbox" id="allRates"
                                                               :value="promotion.all_rate_configs" v-model="promotion.all_rate_configs" @change="checkJrs()">
                                                        <span class="form-check-sign"></span>{{$t('promotion.form.promotion.apply_legend')}}
                                                    </label>
                                                </div>

                                            </div>
                                            <div class="box-rates" v-show="!promotion.all_rate_configs">

                                                <div v-for="rateInfo in promotion_info.rates">
                                                    <label class="fw mt-2 mb-0">{{rateInfo.name}}</label>


                                                    <div class="form-check mb-0"  v-for="rateplan in rateInfo.rateplans">
                                                        <label :for="rateplan.rateconfig_id" class="form-check-label">
                                                            <input class="form-check-input" type="checkbox" :id="rateplan.rateconfig_id"
                                                                   :value="rateplan.rateconfig_id" v-model="rateplan.status"  @change="stepsValidate('type'), checkJrs(false, rateplan.meal_plan)" >
                                                            <span class="form-check-sign"></span>{{rateplan.name}}
                                                        </label>



                                                        <div class="form-check pl-3 mb-0"  v-for="rateLink in  rateplan.rateplansLink">
                                                            <label :for="rateLink.rateconfig_id" class="form-check-label">
                                                                <input class="form-check-input" type="checkbox" :id="rateLink.rateconfig_id"
                                                                       :value="rateLink.rateconfig_id" v-model="rateLink.status"  @change="stepsValidate('type')">
                                                                <span class="form-check-sign"></span>{{rateLink.name}}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" v-show="info_page.index_page===1">


                                        <div class="col-md-4">
                                            <div class="box-button mb-4 ">
                                                <button type="button" class="add-item btn-default" :class="info_page.buttons.type?'':'inactive'"  @click="checkAdvance('page_2')" >
                                                    Siguiente
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-updates form-dates  w-border">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="head  mt-3">
                                                <label class="subtitle">{{$t('promotion.form.booking.title')}}</label>
                                            </div>
                                            <div class="group-edit" v-show="info_page.index_page>2">
                                                <a class="a-right r-20 type-button" @click="info_page.index_page=2"> <i
                                                        class="fa fa-check"></i></a>
                                                <a class="a-right type-text" @click="info_page.index_page=2"> Editar</a>
                                            </div>
                                            <div class="resumen-item" v-show="info_page.index_page>2">


                                                <div v-show="promotion.booking_type===1">
                                                    <span>{{formatConverte(promotion.booking[0], 'f-month')+' '+(promotion.booking_from_hour!==null?formatConverte(promotion.booking_from_hour, 'time'):'--:--')+' hrs. - '+formatConverte(promotion.booking[1], 'f-month')+' '+(promotion.booking_to_hour!==null?formatConverte(promotion.booking_to_hour, 'time'):'--:--')+' hrs.'}}</span>
                                                    <span class="fllw" >Todos los días</span>
                                                </div>
                                                <div v-show="promotion.booking_type===2">

                                                    <span>{{formatConverte(promotion.booking[0], 'f-month')+' - '+formatConverte(promotion.booking[1], 'f-month')}}</span>
                                                    <label class="fllw" >{{$t('promotion.form.booking.between_time')}}</label>
                                                    <span>{{(promotion.booking_time!==null?formatConverte(promotion.booking_time[0], 'time'):'--:--')}} hrs.</span> a
                                                    <span>{{(promotion.booking_time!==null?formatConverte(promotion.booking_time[1], 'time'):'--:--')}} hrs.</span>

                                                    <span class="fllw" v-show="checkWeek()" >Todos los días</span>
                                                    <div  v-show="!checkWeek()">
                                                        <span class="fllw">Días activos:</span>
                                                        <span class="fllw" v-for="(bDays, idxKey) in promotion.booking_days" v-show="bDays">{{ dayName(idxKey) }}</span>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12" v-show="info_page.index_page===2">

                                            <div class="group-item">
                                                <label >{{$t('promotion.form.booking.clients.title')}}</label>
                                                <fg-input v-model="promotion.booking_type" class="input-default"
                                                          style="width: 250px">
                                                    <el-select class="select-default" size="large"
                                                               v-model="promotion.booking_type" style="width: 250px" @change="stepsValidate('booking')">
                                                        <el-option v-for="option in promotion_info.options.reserve"
                                                                   class="select-default" :value="option.value"
                                                                   :label="option.name" :key="option.value">
                                                        </el-option>
                                                    </el-select>
                                                </fg-input>
                                            </div>


                                            <div class="group-item" v-show="promotion.booking_type === 1">
                                                <label class="mr-3" style="width: 300px">{{$t('promotion.form.booking.from')}}</label>
                                                <label class="ml-3" style="width: 300px">{{$t('promotion.form.booking.to')}}</label><br>
                                                <fg-input class="input-default">
                                                    <el-date-picker v-model="promotion.booking[0]" type="date"
                                                                    placeholder="Elige una fecha"
                                                                    format="dd-MM-yyyy"
                                                                    value-format="yyyy-MM-dd"
                                                                    :picker-options="pickerOptions1" @change="stepsValidate('booking')">
                                                    </el-date-picker>
                                                </fg-input>
                                                <fg-input class="input-default ml-3" >
                                                    <el-time-picker
                                                            v-model="promotion.booking_from_hour"
                                                            format="HH:mm"
                                                            placeholder="" @change="stepsValidate('booking')">
                                                        </el-time-picker>
                                                </fg-input>
                                                <fg-input class="input-default ml-3">
                                                    <el-date-picker v-model="promotion.booking[1]" type="date"
                                                                    placeholder="Elige una fecha"
                                                                    format="dd-MM-yyyy"
                                                                    value-format="yyyy-MM-dd"
                                                                    :picker-options="pickerOptions1" @change="stepsValidate('booking')">
                                                    </el-date-picker>
                                                </fg-input>
                                                <fg-input class="input-default ml-3"  >
                                                    <el-time-picker
                                                            v-model="promotion.booking_to_hour"
                                                            format="HH:mm"
                                                            placeholder="" @change="stepsValidate('booking')">
                                                    </el-time-picker>
                                                </fg-input>

                                            </div>


                                            <div class="group-item box-dates mt-4"
                                                 v-show="promotion.booking_type === 2">
                                                <div class="row">
                                                    <div class="col-md-12">


                                                        <label class="mr-3" style="width: 250px">{{$t('promotion.form.booking.from')}}</label>
                                                        <label class="ml-3" style="width: 300px">{{$t('promotion.form.booking.to')}}</label><br>


                                                        <fg-input style="width: 500px">
                                                            <el-date-picker  v-model="promotion.booking"
                                                                            type="daterange" placeholder="Inicio"
                                                                            format="dd-MM-yyyy"
                                                                            :picker-options="pickerOptions1"
                                                                            range-separator="a"
                                                                            start-placeholder="Fecha inicio"
                                                                            end-placeholder="Fecha fin"
                                                                             value-format="yyyy-MM-dd"
                                                                            :default-time="['00:00:00', '23:59:59']"
                                                                            @change="stepsValidate('booking')"
                                                            >
                                                            </el-date-picker>
                                                        </fg-input>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <div class="box-days">

                                                            <label class="mr-3">{{$t('promotion.form.booking.only_days')}}</label>


                                                            <div class="form-check">
                                                                <label for="lunes_d"
                                                                       class="form-check-label check-admin">
                                                                    <input class="form-check-input" type="checkbox"
                                                                           id="lunes_d"
                                                                           v-model="promotion.booking_days[1]" @change="stepsValidate('booking')">
                                                                    <span class="form-check-sign"></span>
                                                                    Lun.
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <label for="martes_d"
                                                                       class="form-check-label check-admin">
                                                                    <input class="form-check-input" type="checkbox"
                                                                           id="martes_d"
                                                                           v-model="promotion.booking_days[2]" @change="stepsValidate('booking')">
                                                                    <span class="form-check-sign"></span>
                                                                    Mar.
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <label for="miercoles_d"
                                                                       class="form-check-label check-admin">
                                                                    <input class="form-check-input" type="checkbox"
                                                                           id="miercoles_d"
                                                                           v-model="promotion.booking_days[3]" @change="stepsValidate('booking')">
                                                                    <span class="form-check-sign"></span>
                                                                    Mie.
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <label for="jueves_d"
                                                                       class="form-check-label check-admin">
                                                                    <input class="form-check-input" type="checkbox"
                                                                           id="jueves_d"
                                                                           v-model="promotion.booking_days[4]" @change="stepsValidate('booking')">
                                                                    <span class="form-check-sign"></span>
                                                                    Jue.
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <label for="viernes_d"
                                                                       class="form-check-label check-admin">
                                                                    <input class="form-check-input" type="checkbox"
                                                                           id="viernes_d"
                                                                           v-model="promotion.booking_days[5]" @change="stepsValidate('booking')">
                                                                    <span class="form-check-sign"></span>
                                                                    Vie.
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <label for="sabado_d"
                                                                       class="form-check-label check-admin">
                                                                    <input class="form-check-input" type="checkbox"
                                                                           id="sabado_d"
                                                                           v-model="promotion.booking_days[6]"  @change="stepsValidate('booking')">
                                                                    <span class="form-check-sign"></span>
                                                                    Sab.
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <label for="domingo_d"
                                                                       class="form-check-label check-admin">
                                                                    <input class="form-check-input" type="checkbox"
                                                                           id="domingo_d"
                                                                           v-model="promotion.booking_days[0]"  @change="stepsValidate('booking')">
                                                                    <span class="form-check-sign"></span>
                                                                    Dom.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="group-item mt-3"
                                                 v-show="promotion.booking_type === 2 && !(promotion_info.visible.advance_options && promotion.option_cutoff === '1')" >
                                                <label class="fllw">{{$t('promotion.form.booking.between_time')}}</label>



                                                <fg-input style="width: 300px" class="input-default"
                                                          :class="(promotion_info.visible.advance_options && activeErrors && promotion.option_cutoff==='1' && (promotion.booking_time === undefined ||
                                                          promotion.booking_time===null ||  promotion.booking_time[0]==='' || promotion.booking_time[1]==='' || promotion.booking_time[0]>=promotion.booking_time[1]))?'has-error':''">
                                                    <el-time-picker
                                                            is-range
                                                            @input="stepsValidate('booking')"
                                                            format="HH:mm"
                                                            v-model="promotion.booking_time"
                                                            range-separator="a"
                                                            start-placeholder="Inicio"
                                                            end-placeholder="Final">
                                                    </el-time-picker>
                                                </fg-input>
                                                <label> Nota: Sí el periodo de tiempo seleccionado es diferente a (00:00 a 23:59) se activará el tag de oferta por tiempo limitado.</label>



                                               <!-- <fg-input class="input-default">
                                                    <el-time-select
                                                            v-model="promotion.booking_time_from"
                                                            :picker-options="{
                                                                      start: '00:00',
                                                                      step: '00:15',
                                                                      end: '23:59',
                                                                      maxTime: promotion.booking_time_to
                                                                    }"
                                                            placeholder="" @change="stepsValidate('booking')" >
                                                    </el-time-select>
                                                </fg-input>


                                                <span class="ml-3">{{$t('promotion.form.booking.a')}}</span>
                                                <fg-input class="input-default ml-3">
                                                    <el-time-select
                                                            v-model="promotion.booking_time_to"
                                                            :picker-options="{
                                                                      start: '00:00',
                                                                      step: '00:15',
                                                                      end: '23:59',
                                                                      minTime: promotion.booking_time_from
                                                                    }"
                                                            placeholder="" @change="stepsValidate('booking')">
                                                    </el-time-select>
                                                </fg-input>-->
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row" v-show="info_page.index_page===2">
                                        <div class="col-md-4">
                                            <div class="box-button mb-4 mt-3">
                                                <button type="button" class="add-item btn-default" :class="info_page.buttons.booking?'':'inactive'"   @click="checkAdvance('page_3')">
                                                    Siguiente
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-dates w-border" >
                                    <div class="box-dates  mt-3">
                                        <div class="row ">
                                            <div class="col-md-12">
                                                <label class="subtitle">{{$t('promotion.form.travel.title')}}</label>
                                                <div class="group-edit" v-show="info_page.index_page>3">
                                                    <a class="a-right r-20 type-button" @click="info_page.index_page=3"> <i class="fa fa-check"></i></a>
                                                    <a class="a-right type-text" @click="info_page.index_page=3"> Editar</a>
                                                </div>
                                                <div class="resumen-item" v-show="info_page.index_page>3">
                                                    <span>{{formatConverte(promotion.travel_selected[0], 'f-month')+' 00:00 hrs. - '+formatConverte(promotion.travel_selected[promotion.travel_selected.length-1], 'f-month')+' 23:59 hrs.'}}</span>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="box-dates"  v-show="info_page.index_page===3">
                                            <div class="row ">
                                                <div class="col-md-12">
                                                    <label class="mr-3" style="width: 250px">{{$t('promotion.form.booking.from')}}</label>
                                                    <label class="ml-3"  style="width: 300px">{{$t('promotion.form.booking.to')}}</label><br>
                                                    <fg-input style="width: 500px" >
                                                        <el-date-picker v-model="promotion.travel_dates" type="daterange"
                                                                        format="dd-MM-yyyy"
                                                                        :picker-options="pickerOptions1"
                                                                        range-separator="a"
                                                                        start-placeholder="Fecha inicio"
                                                                        end-placeholder="Fecha fin"
                                                        >
                                                        </el-date-picker>
                                                    </fg-input>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="box-days">
                                                        <div class="form-check">
                                                            <label for="lunes_day" class="form-check-label check-admin">
                                                                <input class="form-check-input" type="checkbox" id="lunes_day"
                                                                       v-model="calendar_info.days[1]">
                                                                <span class="form-check-sign"></span>
                                                                Lun.
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <label for="martes_day" class="form-check-label check-admin">
                                                                <input class="form-check-input" type="checkbox"
                                                                       id="martes_day" v-model="calendar_info.days[2]">
                                                                <span class="form-check-sign"></span>
                                                                Mar.
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <label for="miercoles_day" class="form-check-label check-admin">
                                                                <input class="form-check-input" type="checkbox"
                                                                       id="miercoles_day" v-model="calendar_info.days[3]">
                                                                <span class="form-check-sign"></span>
                                                                Mie.
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <label for="jueves_day" class="form-check-label check-admin">
                                                                <input class="form-check-input" type="checkbox"
                                                                       id="jueves_day" v-model="calendar_info.days[4]">
                                                                <span class="form-check-sign"></span>
                                                                Jue.
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <label for="viernes_day" class="form-check-label check-admin">
                                                                <input class="form-check-input" type="checkbox"
                                                                       id="viernes_day" v-model="calendar_info.days[5]">
                                                                <span class="form-check-sign"></span>
                                                                Vie.
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <label for="sabado_day" class="form-check-label check-admin">
                                                                <input class="form-check-input" type="checkbox"
                                                                       id="sabado_day" v-model="calendar_info.days[6]">
                                                                <span class="form-check-sign"></span>
                                                                Sab.
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <label for="domingo_day" class="form-check-label check-admin">
                                                                <input class="form-check-input" type="checkbox"
                                                                       id="domingo_day" v-model="calendar_info.days[0]">
                                                                <span class="form-check-sign"></span>
                                                                Dom.
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="box-button mb-5 mt-3">
                                                        <button type="button" class="add-item btn-default"
                                                                :class="((promotion.travel_dates === null ||  ( promotion.travel_dates.length >= 1) && promotion.travel_dates[0]==='' || promotion.travel_dates[1]===''))?'inactive':''" @click="addRangeCalendar" >
                                                            Añadir al calendario
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dates-manually"  v-show="info_page.index_page===3">
                                            <div class="row">
                                                <label class="mb-1">{{$t('promotion.form.travel.add')}}</label>


                                                <label class="item-year text-center color-blue-var year-header mb-3">{{calendar_info.yearHead}}</label>
                                                <div class="box-calendar">
                                                    <div class="item-month"
                                                         v-for="(month_s, idsx) in calendar_info.monthsHead">
                                                        <div class="line-week lw-head">
                                                        <span class="fa fa-chevron-left sp-icon"
                                                              :class="(info_page.btn_back_status)?'':'n-visible'"
                                                              v-if="idsx===0" @click="updDate('minus')"></span>
                                                            <label class="calendar-title wout-icon color-blue-var"
                                                                   :class="checkClass(idsx)" @click="addMonth(month_s)">{{nameMonth(month_s)}}</label>
                                                            <span class="fa fa-chevron-right sp-icon" v-if="idsx===2"
                                                                  @click="updDate('plus')"></span>
                                                        </div>


                                                        <div class="line-week lw-head color-blue-var">
                                                            <div class="item-7" data-key="0">D</div>
                                                            <div class="item-7" data-key="1">L</div>
                                                            <div class="item-7" data-key="2">M</div>
                                                            <div class="item-7" data-key="3">M</div>
                                                            <div class="item-7" data-key="4">J</div>
                                                            <div class="item-7" data-key="5">V</div>
                                                            <div class="item-7" data-key="6">S</div>
                                                        </div>

                                                        <div class="line-week" v-for="day_s in checkMonth(month_s)">
                                                            <div class="item-7" @click.capture="addCalendar(day_s[0])"><span
                                                                    :class="checkDay(day_s[0])">{{dayCalendar(day_s[0])}}</span>
                                                            </div>
                                                            <div class="item-7" @click="addCalendar(day_s[1])"><span
                                                                    :class="checkDay(day_s[1])">{{dayCalendar(day_s[1])}}</span>
                                                            </div>
                                                            <div class="item-7" @click="addCalendar(day_s[2])"><span
                                                                    :class="checkDay(day_s[2])">{{dayCalendar(day_s[2])}}</span>
                                                            </div>
                                                            <div class="item-7" @click="addCalendar(day_s[3])"><span
                                                                    :class="checkDay(day_s[3])">{{dayCalendar(day_s[3])}}</span>
                                                            </div>
                                                            <div class="item-7" @click="addCalendar(day_s[4])"><span
                                                                    :class="checkDay(day_s[4])">{{dayCalendar(day_s[4])}}</span>
                                                            </div>
                                                            <div class="item-7" @click="addCalendar(day_s[5])"><span
                                                                    :class="checkDay(day_s[5])">{{dayCalendar(day_s[5])}}</span>
                                                            </div>
                                                            <div class="item-7" @click="addCalendar(day_s[6])"><span
                                                                    :class="checkDay(day_s[6])">{{dayCalendar(day_s[6])}}</span>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div class="col-md-12">
                                                    <div class="box-legends">
                                                        <label>
                                                            <span class="example-picker"></span>Travel Window
                                                        </label>
                                                        <label>
                                                            <span class="example-picker blocks"></span>Bloqueos
                                                        </label>


                                                        <label class="right-t color-blue" @click="resetCalendar">
                                                            Restablecer
                                                        </label>

                                                        <label class="text-danger fllw mt-2 " style="width: 100%; height: 16px" v-text="info_page.min_date_travel"></label>
                                                    </div>

                                                </div>
                                                <div class="col-md-4">
                                                    <div class="box-button mb-4">
                                                        <button type="button" class="add-item btn-default" :class="info_page.buttons.travel?'':'inactive'"
                                                                :disabled="!info_page.buttons.travel"
                                                                @click="orderDate">Siguiente
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="form-updates form-dates  w-border">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="head  mt-3">
                                                <label class="subtitle">{{$t('promotion.form.info.title')}}</label>
                                            </div>
                                            <div class="group-edit"  v-show="info_page.index_page>4">
                                                <a class="a-right r-20 type-button" @click="info_page.index_page=4"> <i class="fa fa-check"></i></a>
                                                <a class="a-right type-text" @click="info_page.index_page=4"> Editar</a>
                                            </div>
                                            <div class="resumen-item" v-show="info_page.index_page>4">
                                                <span>{{promotion.name}}</span>

                                            </div>







                                        </div>
                                        <div class="col-md-12"  v-show="info_page.index_page === 4"  >

                                            <div class="group-item">
                                                <label>{{$t('promotion.form.info.name')}}</label>
                                                <fg-input type="text"
                                                          class="input-default"
                                                          style="width: 50%"
                                                          placeholder=""
                                                          v-model="promotion.name"
                                                          @input="stepsValidate('name')"
                                                >
                                                </fg-input>
                                                <!--<div class="line-complete">

                                                    <span class="fa fa-exclamation-circle s-icon"></span> <span>{{$t('promotion.form.info.description')}}</span>
                                                </div>-->
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" v-show="info_page.index_page===4">
                                        <div class="col-md-4">
                                            <div class="box-button mb-4 mt-3">
                                                <button type="button" class="add-item btn-default" @click="checkAdvance('page_5')" :class="info_page.buttons.description?'':'inactive'">Siguiente</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-send" v-show="info_page.index_page===5">
                                    <!--<span class="fa fa-exclamation-circle s-icon"></span>
                                  <label>Verifica las actualizaciones, ya que son finales y no puedes regresar a un punto anterior.</label>
                                  -->
                                    <div class="row">
                                        <div class="col-md-12 mt-3 mb-2">
                                            <div class="div">
                                                <span class="text-danger fllw" v-for="error_is in errores_custom" v-text="'*'+error_is"></span>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="box-button mb-4">
                                        <button type="button" class="add-item btn-success" :class="info_page.buttons.create?'':'inactive'" @click="sendInfo()" > {{info_page.button}}</button>
                                        <button type="button" class="add-item second btn-danger"  @click="cancelAction()">Cancelar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>

</template>
<script>
    import swal from 'sweetalert2'
    import {Breadcrumb, BreadcrumbItem, Radio, Switch as LSwitch} from 'src/components/index'
    import {Select, Option, DatePicker, TimeSelect, TimePicker, Input} from 'element-ui'
    //import users from 'src/pages/Dashboard/Tables/users'
    import moment from 'moment';
    import PropertyService from '../../../js/services/PropertyService';
    import RoomService from '../../../js/services/RoomService';
    import RateService from '../../../js/services/RateService';
    import PromotionService from '../../../js/services/PromotionService';

    import Settings from "src/js/helpers/Settings";
    let settings = new Settings();

    import EventBus from "../../../js/helpers/EventBus";
    import {vueTopprogress} from 'vue-top-progress'
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'
    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';


    const rateService = new RateService();
    const promotionService = new PromotionService();
    const propertyService = new PropertyService();
    const roomService = new RoomService();
    const logFunctions = new LogFunctions();
    const logService = new LogService();


    import {mapGetters} from "vuex";

    export default {
        components: {
            [DatePicker.name]: DatePicker,
            [TimeSelect.name]: TimeSelect,
            [TimePicker.name]: TimePicker,
            Breadcrumb,
            BreadcrumbItem,
            vueTopprogress,
            [Option.name]: Option,
            [Select.name]: Select,
            LSwitch,
            Radio
        },
        computed: {
            ...mapGetters(["currentUser","selected_property"]),
        },
        data() {
            return {
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },
                activeErrors: false,
                screenLoading: false,
                errores_custom: [],
                formImcompleted: false,
                screenText: '',
                jrs_block: {
                    show: false,
                    active: false,
                },
                breadcrumbs: [
                    {
                        name: this.$t('property.index.home'),
                        path: '/admin/properties',
                        type: '',
                    },
                    {
                        name: this.$t('promotion.index.title'),
                        path: '/admin/promotions',
                        type: '',
                    },

                    {
                        name: this.$t('promotion.new.title'),
                        path: '',
                        type: 'active',
                    }
                ],
                info_page: {
                    original_info:{},
                    section_name: 'promotion',
                    property: null,
                    jrs: false,
                    children: false,
                    action: 'add',
                    header: this.$t('promotion.new.header'),
                    button:  this.$t('promotion.new.button'),
                    property_id: {},
                    index_page: 1,
                    tab_lang: 'es',
                    langs: [{
                        name: this.$t('locale_es'),
                        locale: 'es'
                    }, {
                        name: this.$t('locale_en'),
                        locale: 'en'
                    },],
                    states: [
                        {
                            name: 'Sin cambios',
                            value: 0
                        },
                        {
                            name: 'Abierta',
                            value: 1
                        },
                        {
                            name: 'Cerrada',
                            value: 2
                        }
                    ],
                    room_model: {
                        name: 'title',
                        value: '',
                        status: 0,
                    },
                    btn_back_status: false,
                    buttons: {
                        type: false,
                        booking: false,
                        travel: false,
                        description: false,
                        create: true
                    },
                    min_date_travel: null,
                },


                calendar_info: {
                    yearHead: '',
                    monthsHead: [],
                    selected: [],
                    min: '',
                    max: '',
                    availables_days: ['-1'],
                    days: [
                        1, 1, 1, 1, 1, 1, 1
                    ],
                },
                promotion_info: {
                    rates:[],
                    options: {
                        type: [
                            {
                                name: 'Porcentaje',
                                value: '%'
                            },
                           /* {
                                name: 'Cantidad',
                                value: '$'
                            }*/
                        ],
                        discount: [
                            {
                                name: 'Noche gratis',
                                value: '1'
                            },
                            {
                                name: 'Noche con descuento',
                                value: '2'
                            }
                        ],
                        aggregates:[
                            {
                                name: 'Resort Credit',
                                value: 'resort-credit'
                            },
                            {
                                name: 'Spa Credit',
                                value: 'spa-credit'
                            },
                            {
                                name: 'Tour',
                                value: 'tour'
                            },
                            {
                                name: 'Golf',
                                value: 'golf'
                            },
                            {
                                name: 'Bottle of Wine',
                                value: 'bottle-of-wine'
                            },
                            {
                                name: 'Breakfast',
                                value: 'breakfast'
                            },
                            {
                                name: 'Dinner',
                                value: 'dinner'
                            },
                            {
                                name: 'Mini bar',
                                value: 'mini-bar'
                            },
                            {
                                name: 'Free Upgrade',
                                value: 'free-upgrade'
                            },
                            {
                                name: 'Wi-Fi',
                                value: 'internet'
                            },
                            {
                                name: 'Early Check In',
                                value: 'early-check-in'
                            },
                            {
                                name: 'Late Check Out',
                                value: 'late-check-out'
                            },
                            {
                                name: 'Vip Check In',
                                value: 'vip-check-in'
                            },

                        ],
                        discount_apply: [
                            {
                                name: 'Segunda',
                                value: 2
                            },
                            {
                                name: 'Tercera',
                                value: 3
                            },
                            {
                                name: 'Cuarta',
                                value: 4
                            },
                            {
                                name: 'Quinta',
                                value: 5
                            },
                            {
                                name: 'Sexta',
                                value: 6
                            },
                            {
                                name: 'Séptima',
                                value: 7
                            }
                        ],
                        reserve: [
                            {
                                name: 'Por periodo de tiempo',
                                value: 1
                            },
                            {
                                name: 'Días y horas específicas',
                                value: 2
                            }
                        ]
                    },
                    visible: {
                        min_nights: false,
                        advance_options: false,
                        night_free: false,
                        children_free: false,
                        jrs_free: false,
                        aggregates: false,
                        type: false,
                        booking: false,
                        travel: false,
                        info: false,

                    }
                },
                promotion: {
                    _id: '',
                    name: "",
                    booking_time_from: null,
                    booking_time: null,
                    booking_time_to: null,
                    free_night: null,
                    free_night_limit_to_stay: false,
                    free_night_active: false,
                    free_children_active: false,
                    free_children: null,
                    free_children_type: '1',
                    free_children_night: 2,
                    free_jrs_active: false,
                    free_jrs: null,
                    free_jrs_type: '1',
                    free_jrs_night: 2,
                    aggregated_active :false,
                    aggregated_values: [],
                    booking_hours: ['', ''],
                    property_id: null,
                    min_nights: null,
                    max_nights: null,
                    max_nights_status: false,
                    min_cutoff: 0,
                    max_cutoff: null,
                    hour_cutoff: 0,
                    max_cutoff_status: false,
                    option_cutoff: '0',
                    type: '0',
                    discount_type: "%",
                    value: "",
                    value_days: [
                        0,0,0,0,0,0,0
                    ],
                    max_reservations: null,
                    max_reservations_status: false,
                    all_rate_configs: true,
                    rate_configs: [],
                    booking: ["", ""],
                    booking_type: 1,
                    booking_from_hour: null,
                    booking_to_hour: null,
                    booking_from: '',
                    booking_to: '',
                    booking_days: [
                        1, 1, 1, 1, 1, 1, 1
                    ],
                    travel_dates:["", ""],
                    travel_selected: [
                    ],
                    discount_night: null,
                    limit_to_stay: false,
                    status: true

                },

                pickerOptions1: {
                    disabledDate: time => {
                        var d = new Date();
                        d.setDate(d.getDate() - 1);
                        //console.log('day', d)
                        return time <= d.getTime()
                    }

                },

            }
        },
        created() {
            this.chargerItems(this.selected_property._id);
            moment.locale('es');
        },
        methods: {
            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                    if(this.$route.name==='editPromotion'){
                        this.$router.push({
                            name: 'Promotions'
                        });
                    }if(this.$route.name==='CreatePromotion'){
                        this.chargerItems(property_id)
                    }
                })
            },
            roomShow(rateInfo){
                let statusIs = false;
                rateInfo.rateplans.forEach(rateplan =>{
                    if (rateplan.status){
                        statusIs = true;
                    }
                    rateplan.rateplansLink.forEach(rateplanLink =>{
                        if (rateplanLink.status){
                            statusIs = true;
                        }
                    })
                });
                return statusIs;
            },
            checkJrs(all = true, type_contract = 1){
                //console.log('jrs_block', all);
                let jrs_block = false;
                let jrs_active = false;
                this.jrs_block.active = false;
                this.promotion_info.rates.forEach(roomInfo=>{
                    roomInfo.rateplans.forEach(rateplanInfo=>{
                        if(rateplanInfo.meal_plan===3){
                            jrs_block = true;
                            if(rateplanInfo.status){
                                jrs_active = true;
                                //console.log('jrs_active', jrs_active);
                            }
                        }
                    })
                });

                //console.log('jrs_active', jrs_active, jrs_block);
                if(this.info_page.jrs && jrs_block ){
                    //console.log('if pass', jrs_block.active);
                    this.jrs_block.show = true;
                    if(jrs_active || this.promotion.all_rate_configs){
                        //console.log('jrs_active sec', jrs_block.active);
                        this.jrs_block.active = true;
                        return;
                    }else{
                        this.promotion.free_jrs_active = false;
                        if(all===false && type_contract === 3){
                            this.alertError('Advertencia', 'Es necesario activar un contrato Todo Incluido para ver la sección de Juniors')
                        }
                    }
                }
                return
            },


            nextSection(value_) {

               //console.log('avanzando seccion')
                switch (value_) {

                    case 'booking':

                        this.info_page.index_page = 2;

                }
            },
            async selectRoomContracts(){

                let rateConfigs = this.promotion.rate_configs;
                this.stepsValidate('type');

                if(this.promotion_info.rates.length===0){
                    let info_data = {
                        property: this.selected_property._id,
                        from: moment().format('YYYY-MM-DD'),
                        to: moment().format('YYYY-MM-DD'),
                        rateplan_status: 1
                    };
                    let strcutureRates = [];


                    let getrates = await rateService.getRates(info_data)
                    getrates = getrates.data.data.rooms
                    getrates.forEach(gRate=>{
                        let roomInfo = {
                            _id: gRate._id.room_id,
                            name: gRate._id.name,
                            status: false,
                            rateplans:[]

                        }
                        gRate.rates_plan.forEach((rateplan, idxKey)=>{
                            //console.log('s rateplan', rateplan);
                            let rateplanInfo ={
                                _id : rateplan.rateplan_data._id,
                                rateconfig_id : rateplan.rateconfig_id,
                                name: rateplan.rateplan_data.name,
                                meal_plan: rateplan.rateplan_data.meal_plan,
                                rateplansLink:[],
                                status: false
                            }



                            if(rateConfigs.indexOf(rateplan.rateconfig_id)>=0){
                                rateplanInfo.status=true;

                            }
                            if(rateplan.rateplanlink_data.length){
                                let elementFind = roomInfo.rateplans.find(item=> item._id===rateplan.rateplanlink_data[0].rateplan)
                                let rateplanLinkItem = {
                                    _id : rateplan.rateplan_data._id,
                                    rateconfig_id : rateplan.rateconfig_id,
                                    name: rateplan.rateplan_data.name,
                                    status: false
                                }
                                if(rateConfigs.indexOf(rateplanLinkItem.rateconfig_id)>=0){
                                    rateplanLinkItem.status=true;

                                }

                                elementFind.rateplansLink.push(rateplanLinkItem);
                                /*console.log('elementFind', elementFind);
                                gRate.rates_plan.splice(idxKey, 1);*/
                            }else{
                                roomInfo.rateplans.push(rateplanInfo);
                            }

                        });
                        strcutureRates.push(roomInfo)
                    })
                    //console.log('strcutureRates', strcutureRates);
                    this.promotion_info.rates = strcutureRates;
                    this.checkJrs();
                }


            },

            async getInfoProperty(property_id){
                //console.log('this.selected_property', this.selected_property);
                let propertyResponse = this.selected_property;

                //propertyResponse = propertyResponse.data.data.property;
                this.info_page.property =propertyResponse;
                this.info_page.children = (!propertyResponse.only_adults && propertyResponse.age_child)?true:false;
                this.info_page.jrs = (!propertyResponse.only_adults && propertyResponse.age_jr)?true:false;
                //return propertyResponse.data.data.property;age_jr
            },
            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 0;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },
            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },

            chargerItems(property_id = null) {
                this.info_page.property_id = property_id;
                this.info_page.index_page = 1;
                this.initLoading();
                this.configLogs(property_id, this.info_page.section_name);
                this.getInfoProperty(property_id)
                let date_ = moment().locale('es');
                this.promotion = {
                    _id: '',
                    name: "",
                    booking_time_from: null,
                    booking_time_to: null,
                    booking_time: [moment(moment().format('YYYY-MM-DD')+' 00:00', 'YYYY-MM-DD HH:mm'), moment(moment().format('YYYY-MM-DD')+' 23:59', 'YYYY-MM-DD HH:mm')],
                    free_night: null,
                    free_night_limit_to_stay: false,
                    free_night_active: false,
                    free_children_active: false,
                    free_children: 1,
                    free_children_type: '1',
                    free_children_night: 2,
                    free_jrs_active: false,
                    free_jrs: 1,
                    free_jrs_type: '1',
                    free_jrs_night:2,
                    booking_hours: ['', ''],


                    aggregated_active :false,
                    aggregated_values: [
                        {
                            name: '',
                            text_es: '',
                            text_en: '',
                        }

                    ],
                    property_id: null,
                    min_nights: null,
                    max_nights: null,
                    max_nights_status: false,
                    min_cutoff: 0,
                    max_cutoff: null,
                    hour_cutoff: 0,
                    max_cutoff_status: false,
                    option_cutoff: '0',
                    type: '0',
                    discount_type: "%",
                    value: "",
                    value_days: [
                    0,0,0,0,0,0,0
                ],
                    max_reservations: null,
                    max_reservations_status: false,
                    all_rate_configs: true,
                    rate_configs: [],
                    booking: ["", ""],
                    booking_type:1,
                    booking_from_hour:  moment(moment().format('YYYY-MM-DD')+' 00:00', 'YYYY-MM-DD HH:mm'),
                    booking_to_hour:  moment(moment().format('YYYY-MM-DD')+' 23:59', 'YYYY-MM-DD HH:mm'),
                    booking_from: '',
                    booking_to: '',
                    booking_days: [
                    1, 1, 1, 1, 1, 1, 1
                ],
                    travel_dates:["", ""],
                    travel_selected: [
                ],
                    discount_night: null,
                    limit_to_stay: false,
                    status: true

                };
                this.promotion.property_id = property_id;





                if(this.$route.name==='editPromotion'){
                    let promotion_id = this.$route.params.id;
                    this.info_page.action = 'edit';
                    this.log_page.action = 1;
                    this.info_page.header = this.$t('promotion.edit.header');
                    this.info_page.button = this.$t('promotion.edit.button');
                    this.breadcrumbs[this.breadcrumbs.length-1].name = this.$t('promotion.edit.header');
                    this.updateData(promotion_id);
                }else{
                    setTimeout(()=>{
                        this.closeLoading();
                    }, 500)
                }

                this.selectRoomContracts();
                /* roomService.getRooms('true', property_id).then(response => {
                     response.data.data.rooms.forEach(async tnmt => {
                         this.inventory.rooms.push({
                             _id:tnmt._id,
                             name: tnmt.name,
                             value: '',
                             status: 0,
                         });
                     });

                     this.$refs.topProgress.done()
                 });*/


                /*let start_month = date_.format('MM-YYYY');
                let middle_month = date_.add(1, 'M').format('MM-YYYY');
                let end_month = date_.add(1, 'M').format('MM-YYYY');
                //this.calendar_info.monthsHead = [start_month];

                this.calendar_info.monthsHead = [start_month, middle_month, end_month ];*/
                this.calendarDashboard(date_);
            },
            async updateData(promotionId){
                let promotion = await promotionService.getPromotion(promotionId);
                this.promotion._id = promotionId;
                promotion = promotion.data.data.promotion;
                this.info_page.original_info = JSON.parse(JSON.stringify(promotion))
               //console.log(promotion);
                //this.promotion = promotion


                this.promotion._id = promotion._id;
                this.promotion.name = promotion.name;

                if(promotion.booking_time_from===undefined){
                    promotion.booking_time_from = '00:00';
                }
                if(promotion.booking_time_to===undefined){
                    promotion.booking_time_to = '23:59'
                }


                this.promotion.booking_time_from = promotion.booking_time_from;
                this.promotion.booking_time_to = promotion.booking_time_to;


                if(promotion.booking_time_from!==null){
                    this.promotion.booking_time = [moment(promotion.booking_time_from, 'HH:mm'), moment(promotion.booking_time_to, 'HH:mm')]
                }


                this.promotion.free_night = promotion.free_night===undefined || promotion.free_night===null?0:promotion.free_night;
                this.promotion.free_night_limit_to_stay = promotion.free_night_limit_to_stay===undefined?false:promotion.free_night_limit_to_stay;
                this.promotion.free_night_active = promotion.free_night_active===undefined?null:promotion.free_night_active;

                this.promotion.free_children_active = promotion.free_children_active===undefined?false:promotion.free_children_active;
                this.promotion.free_children = promotion.free_children===undefined?0:promotion.free_children;
                this.promotion.free_children_type = promotion.free_children_type===undefined?'1':promotion.free_children_type+'';
                this.promotion.free_children_night = promotion.free_children_night===undefined?2:promotion.free_children_night;



                this.promotion.free_jrs_active = promotion.free_jrs_active===undefined?false:promotion.free_jrs_active;
                this.promotion.free_jrs = promotion.free_jrs===undefined?0:promotion.free_jrs;
                this.promotion.free_jrs_type = promotion.free_jrs_type===undefined?'1':promotion.free_jrs_type+'';
                this.promotion.free_jrs_night = promotion.free_jrs_night===undefined?2:promotion.free_jrs_night;

                this.promotion.aggregated_active = promotion.aggregated_active===undefined?false:promotion.aggregated_active;
                this.promotion.aggregated_values =  promotion.aggregated_values===undefined?[]:promotion.aggregated_values;

                this.promotion.property_id = promotion.property_id;




                this.promotion.min_nights = promotion.min_nights;
                this.promotion.max_nights = promotion.max_nights;
                //this.promotion.max_nights_status = promotion.max_nights_status;
                this.promotion.min_cutoff = promotion.min_cutoff;
                this.promotion.max_cutoff = promotion.max_cutoff;

                //this.promotion.option_cutoff = promotion.option_cutoff;

                this.promotion.option_cutoff =((promotion.max_cutoff===0 && promotion.min_cutoff===0) || (promotion.max_cutoff===null && promotion.min_cutoff===null))?'1':'0'
                this.promotion_info.visible.advance_options =
                    ((promotion.max_cutoff !==undefined && promotion.max_cutoff!==null ) ||
                        (promotion.min_cutoff!==undefined && promotion.min_cutoff!==null ));
                this.promotion.type = promotion.type+'';
                this.promotion.discount_type = promotion.discount_type;
                this.promotion.value = promotion.value;
                this.promotion.value_days = promotion.value_days;





                this.promotion.max_reservations = promotion.max_reservations;
                //this.promotion.max_reservations_status = promotion.max_reservations_status;
                this.promotion.all_rate_configs = promotion.all_rate_configs;
                this.promotion.rate_configs = promotion.rate_configs;
                //this.promotion.booking = promotion.booking;
                this.promotion.booking_type = promotion.booking_type===undefined?1:promotion.booking_type;;
                //this.promotion.booking_from_hour = promotion.booking_from_hour;
                this.promotion.booking_from = moment(promotion.booking_from).utc().format('YYYY-MM-DD');
                //this.promotion.booking_to_hour = promotion.booking_to_hour;
                this.promotion.booking_to = moment(promotion.booking_to).utc().format('YYYY-MM-DD');
                //this.promotion.booking_days = promotion.booking_days;
                this.promotion.travel_dates = ['', ''];
                //this.promotion.travel_selected = promotion.travel_selected;
                //this.promotion.discount_night = promotion.discount_night;
                //this.promotion.limit_to_stay = promotion.limit_to_stay;
                this.promotion.status = promotion.status;
                console.log(this.promotion.booking_from, this.promotion.booking_to);


                let booking_days = [0,0,0,0,0,0,0];

                promotion.booking_days.forEach((days_in, idx_in)=>{
                    //days_in = (days_in===7)?0:days_in;
                    booking_days[days_in] = 1;
                });

                this.promotion.booking_days = booking_days;
                let arrayDates =[];
                promotion.travel_dates.forEach(item=>{
                    arrayDates.push(moment(item).utc().format('YYYY-MM-DD'));
                })
                if(this.promotion.type==='1'){
                    this.promotion.value_days = promotion.value_days;
                }
                if(this.promotion.type==='2'){
                    this.promotion.value = promotion.value+'';
                    this.promotion.discount_night = promotion.discount_night;
                    this.promotion.limit_to_stay = promotion.limit_to_stay;
                }

                this.promotion.travel_selected = arrayDates;
                this.promotion.max_reservations_status =  (promotion.max_reservations!==null);
                this.promotion.max_cutoff_status =  (promotion.max_cutoff!==null && promotion.max_cutoff>0);
                this.promotion.max_nights_status =  (promotion.max_nights!==null);
                this.promotion_info.visible.min_nights =  (promotion.max_nights!==null || promotion.min_nights !== null);

                /*if(promotion.booking_days.length){
                    this.promotion.booking_type = '1';
                }else{
                    this.promotion.booking_type = '0';
                }*/
                this.promotion.booking_from_hour = moment(promotion.booking_from, 'YYYY-MM-DD HH:mm')
                this.promotion.booking_to_hour = moment(promotion.booking_to, 'YYYY-MM-DD HH:mm')

                this.promotion.booking = [moment(promotion.booking_from).utc().format('YYYY-MM-DD'), moment(promotion.booking_to).utc().format('YYYY-MM-DD')]

                //moment(promotion.booking_time_from, 'HH:mm')

                this.info_page.buttons.type = true;
                this.info_page.buttons.booking = true;
                this.info_page.buttons.travel = true;
                this.info_page.buttons.description = true;
                this.info_page.buttons.create = true;
                this.selectRoomContracts();
                this.stepsValidate('type')

                this.closeLoading();
            },
            printCalendar() {
               //console.log('Se agrego al calendario');
                this.addRangeCalendar();
            },
            addRangeCalendar() {
                let array_dates = this.promotion.travel_dates;

                let date_selecteds = this.promotion.travel_selected;
                let range_start = moment(array_dates[0]);
                let range_end = moment(array_dates[1]);

                let weeekdays = this.calendar_info.days;

                while (range_start <= range_end) {

                    let status_day = weeekdays[range_start.day()];
                    if (status_day) {
                        let idx = date_selecteds.indexOf(range_start.format('YYYY-MM-DD'));
                        if (idx < 0) {
                            this.promotion.travel_selected.push(range_start.format('YYYY-MM-DD'))
                        }
                    }
                    range_start.add(1, 'days');
                }
                //this.checkButtonAvailable('page_1');
                let date_end_now = moment(this.calendar_info.monthsHead[2], 'MM-YYYY').add(1, 'M').startOf('month');
                let date_start_new = moment(range_start)
                this.promotion.travel_dates = ['', '']
                if(date_start_new>date_end_now){
                    this.calendarDashboard(range_start)
                    this.checkDateNow()
                }

            },
            addMonth(month_s) {
                let start_of = moment(month_s, 'MM-YYYY').startOf('month')
                let daysMonth = moment(month_s, 'MM-YYYY').endOf('month');
                let date_selecteds = this.promotion.travel_selected;
                let array_result = [];

                while (start_of < daysMonth) {


                    let aux = start_of.format('YYYY-MM-DD')
                    let date_now = moment();
                    if (!moment(date_now.format('YYYY-MM-DD')).isAfter(aux, 'day')) {

                        //console.log('es un dia disponible', aux)
                        let idx = date_selecteds.indexOf(start_of.format('YYYY-MM-DD'));
                        if (idx < 0) {
                            array_result.push(start_of.format('YYYY-MM-DD'))
                        }
                    }

                    start_of.add(1, 'days')
                }
                //console.log('arr_r', array_result.length)

                if (array_result.length === 0) {
                    this.deleteMonth(month_s)
                } else {
                    this.promotion.travel_selected = date_selecteds.concat(array_result)
                }
                //this.stepsValidate('travel');
                //this.checkButtonAvailable('page_1');

            },
            deleteMonth(month_s) {
                let start_of = moment(month_s, 'MM-YYYY').startOf('month')
                let daysMonth = moment(month_s, 'MM-YYYY').endOf('month');
                let date_selecteds = this.promotion.travel_selected;

                while (start_of < daysMonth) {

                    let idx = date_selecteds.indexOf(start_of.format('YYYY-MM-DD'));
                    if (idx >= 0) {
                        date_selecteds.splice(idx, 1)
                    }
                    start_of.add(1, 'days')
                }
                //this.stepsValidate('travel');
            },
            resetCalendar() {
                this.promotion.travel_selected = [];
                this.calendar_info.days = [1, 1, 1, 1, 1, 1, 1];
                this.promotion.travel_dates = ['', ''];
                //this.stepsValidate('travel');
            },
            calendarDashboard(date_) {
                let originalDate = moment(date_)
                let start_month = date_.format('MM-YYYY');
                let middle_month = date_.add(1, 'M').format('MM-YYYY');
                let end_month = date_.add(1, 'M').format('MM-YYYY');

                //this.calendar_info.monthsHead = [start_month];

                this.calendar_info.monthsHead = [start_month, middle_month, end_month];
                this.calendar_info.yearHead = originalDate.add(1, 'M').format('YYYY');

            },
            dayCalendar(date) {
                let aux = '';
                if (date !== null) {
                    aux = moment(date, 'YYYY-MM-DD').format('D')
                }

                return aux
            },
            addCalendar(date) {
                let dates_Array = this.promotion.travel_selected;
                if (date !== null) {


                    let aux = date
                    let date_now = moment();
                    if (!moment(date_now.format('YYYY-MM-DD')).isAfter(aux, 'day')) {

                        //console.log('es un dia disponible')
                        let idx = dates_Array.indexOf(date);
                        if (idx >= 0) {
                            dates_Array.splice(idx, 1)

                        } else {
                            dates_Array.push(date);
                        }
                    }
                }

                //this.stepsValidate('travel');
            },

            nameMonth(date) {
                return moment(date, 'MM-YYYY').format('MMMM')
            },

            updDate(action) {
                let date = moment(this.calendar_info.monthsHead[0], 'MM-YYYY')
                if (action === 'plus') {
                    date.add(1, 'M')
                    this.calendarDashboard(date)

                } else if (action === 'minus') {
                    date.subtract(1, 'M')
                    this.calendarDashboard(date)
                }
                this.checkDateNow();

            },
            checkDateNow() {

                let date = moment(this.calendar_info.monthsHead[0], 'MM-YYYY')
                let date_now = moment();

                //console.log('comparando mes', month_now, date, )
                this.info_page.btn_back_status = date.isAfter(date_now, 'month')

            },


            checkDay(date) {
                let dates_Array = this.promotion.travel_selected;
                let response = '';
                let resp_status = '';

                if (date !== null) {
                    response = dates_Array.indexOf(date);
                    if (response >= 0) {
                        resp_status = 'select-day';
                    }

                    let aux = date
                    let date_now = moment();
                    if (moment(date_now.format('YYYY-MM-DD')).isAfter(aux, 'day')) {


                        resp_status = 'block-day';
                        //console.log('bloquear date', moment(aux).isSameOrAfter(date_now.format('YYYY-MM-DD'), 'day'))

                    }
                }
                this.stepsValidate('travel');
                return resp_status;
            },

            checkClass(idx) {
                let result_class = '';
                if (idx === 0) {
                    result_class += 'w-80';
                }
                else if (idx === 2) {
                    result_class += 'ml-20 w-80';
                }
                return result_class
            },

            checkMonth(month_s) {
                let start_of = moment(month_s, 'MM-YYYY').startOf('month')
                let daysMonth = moment(month_s, 'MM-YYYY').endOf('month');
                let array_result = [];
                let array_model = [null, null, null, null, null, null, null];
                //console.log('month_s', start_of.week)

                while (start_of < daysMonth) {

                    array_model[start_of.day()] = start_of.format('YYYY-MM-DD');
                    if (start_of.day() === 6) {

                        array_result.push(array_model);
                        array_model = [null, null, null, null, null, null, null];
                    }

                    start_of.add(1, 'days')
                }

                array_result.push(array_model);
                //console.log('array_result', array_result)
                return array_result;

            },
            checkStatus(status) {
                //console.log(status)

                let states_ = this.info_page.states[status];

                //states_.find(item => item.value === status)


                return states_.name

            },
            orderDate() {
                let calendar_array = this.promotion.travel_selected;
                //console.log('orderDate', )
                calendar_array = calendar_array.sort()

                this.calendar_info.min = moment(calendar_array[0]).format('DD MMM YYYY');
                this.calendar_info.max = moment(calendar_array[calendar_array.length - 1]).format('DD MMM YYYY');

                /*this.info_page.index_page = 2;
                this.checkDaysAvailables()*/

                this.info_page.index_page = 4;
                this.checkAdvance('page_4');


            },
            checkDaysAvailables() {
                //console.log('checkDaysAvailables')
                let dates_Array = this.promotion.travel_selected;
                let array_aux = [];

                dates_Array.forEach(item => {

                    let result = array_aux.indexOf(moment(item).day());
                    if (result < 0) {
                        array_aux.push(moment(item).day())
                    }
                })

                this.calendar_info.availables_days = array_aux.sort()

                let avai_days = this.calendar_info.availables_days;
                let ky_sunday = avai_days.indexOf(0)
                if (ky_sunday >= 0) {
                    avai_days.splice(ky_sunday, 1);
                    avai_days.push(0);
                }
                //console.log('this.calendar_info.availables_days', this.calendar_info.availables_days)

            },

            addElement(section){

                switch (section) {
                    case 'type':
                        this.promotion.max_reservations_status = true;
                        this.stepsValidate(section);
                        break;
                    case 'min_nights':
                        this.promotion.max_nights_status = true
                        //this.promotion.max_reservations_status = true;
                        //this.stepsValidate('create');
                        break;
                    case 'min_cutoff':
                        this.promotion.max_cutoff_status = true
                        //this.promotion.max_reservations_status = true;
                        //this.stepsValidate('create');
                        break;
                    case 'add_aggregates':
                        this.promotion.aggregated_values.push({
                            name: '',
                            text_es: '',
                            text_en: ''
                        })
                        break;



                }
            },

            deleteElement(section) {

                switch (section) {
                    case 'min_nights':
                        this.promotion.max_nights_status = false;
                        this.promotion.max_nights = '';
                        //this.stepsValidate('create');
                        break;
                    case 'advance_options':
                        this.promotion.max_cutoff_status = false;
                        this.promotion.max_cutoff_status = '';
                        break;
                    case 'type':
                        this.promotion.max_reservations_status = false;
                        this.promotion.max_reservations = '';
                        this.stepsValidate(section);
                        break;
                }

            },
            deleteAggregated(idx){
                this.promotion.aggregated_values.splice(idx, 1)
            },
            createObjectQuick(value, text, type, min_value = 0){
              return  {
                  value,
                  text,
                  type,
                  min_value
              }
            },
            validadateForm(){
                let arrayRespuesta = [];
                let arrayToValidate = [];
                let errorResponse = false;
                let promotion = {...this.promotion};
                //console.log('promotion', promotion);
                let promotion_info = this.promotion_info;

                if(promotion.free_night_active){
                    arrayToValidate.push(this.createObjectQuick(promotion.free_night, 'Noche gratis', 'number', 1))
                }

                if(promotion_info.visible.min_nights){
                    arrayToValidate.push(this.createObjectQuick(promotion.min_nights, 'Mínimo de noches', 'number', 1))
                    if(promotion.max_nights_status){
                        arrayToValidate.push(this.createObjectQuick(promotion.max_nights, 'Máximo de noches', 'number', 2))
                        arrayToValidate.push(this.createObjectQuick(promotion.max_nights, 'Mínimo de noches no pueder ser igual ó menor al Máximo de noches', 'min_than', promotion.min_nights))
                    }
                }
                if(promotion_info.visible.advance_options){
                    //arrayToValidate.push(this.createObjectQuick(promotion.min_nights, 'Mínimo de noches', 'number', 1))
                    if(promotion.option_cutoff === '0'){
                        arrayToValidate.push(this.createObjectQuick(promotion.min_cutoff, 'Mínimo de días de antelación', 'number', 1))
                        if(promotion.max_cutoff_status){
                            arrayToValidate.push(this.createObjectQuick(promotion.max_cutoff, 'Máximo de días de antelación', 'number', 2))
                            arrayToValidate.push(this.createObjectQuick(promotion.max_cutoff, 'Mínimo de días de antelación no pueder ser igual ó menor al Máximo de días de antelación', 'min_than', promotion.min_cutoff))

                        }



                    }
                    if(promotion.option_cutoff === '1'){
                        if(promotion.booking_time===null){
                            promotion.booking_time = ['', '']
                            arrayToValidate.push(this.createObjectQuick(promotion.booking_time[0], 'Hora de inicio antelación', 'string', 0))
                            arrayToValidate.push(this.createObjectQuick(promotion.booking_time[1], 'Hora de fin antelación', 'string', 0))
                        }else{
                            arrayToValidate.push(this.createObjectQuick(promotion.booking_time[1], 'Hora de fin antelación no pueder ser igual ó menor a la Hora de inicio antelación', 'min_time', promotion.booking_time[0]))
                        }
                    }

                }
                if(promotion.aggregated_active){
                    arrayToValidate.push(this.createObjectQuick(promotion.aggregated_values, 'Máximo de noches', 'array', 0))

                }



                arrayRespuesta = this.fieldsValidate(arrayToValidate);
                console.log('array respuesta:', arrayRespuesta);
                this.errores_custom = arrayRespuesta.fields;
                return !arrayRespuesta.status;

            },


            fieldsValidate(fields){
                let booleanStatus = false;
                let arrayResponses = [];
                fields.forEach(field_info=>{
                    let field = field_info.value;
                    let text = field_info.text;
                    let type = field_info.type;
                    let min_value = field_info.min_value;
                    if(type === 'string'){
                        field = field+'';
                        if(field==='null' ||  field==='' || field.trim().length === 0 ){
                            booleanStatus = true ;
                            arrayResponses.push('El campo '+text+' es requerido');
                        }
                    }
                    if(type==='number'){
                        if(isNaN(parseFloat(field)) || !isFinite(field)){
                            booleanStatus = true ;
                            arrayResponses.push('El campo '+text+' es requerido');
                        }
                        if(parseFloat(field)<min_value){
                            booleanStatus = true ;
                            arrayResponses.push('La cantidad  mínima requerida para '+text+' es: '+min_value );
                        }
                    }
                    if(type==='array'){

                        if(field.length===0){
                            arrayResponses.push('Es necesario agregar un valor agregado');
                            return;
                        }

                        field.forEach(item=>{
                            field = field+'';
                            if(item.name==='null' ||  item.name==='' || item.name.trim().length === 0 ||
                                item.text_es==='null' ||  item.text_es==='' || item.text_es.trim().length === 0  ||
                                item.text_en==='null' ||  item.text_en==='' || item.text_en.trim().length === 0  ){
                                booleanStatus = true ;
                                let checkIdx = arrayResponses.findIndex(itemT=> itemT === 'Es necesario completar todos los campos de los valores agregados')

                                console.log(checkIdx, arrayResponses);

                                if(checkIdx<0){
                                    arrayResponses.push('Es necesario completar todos los campos de los valores agregados');
                                }

                                return;
                            }
                        })

                    }
                    if(type==='min_than'){
                        //console.log(parseInt(field), min_value, 'min_than');
                        if(parseInt(field)<=min_value){
                            booleanStatus = true ;
                            arrayResponses.push(text);
                        }
                    }
                    if(type==='min_time'){
                        if(field<=min_value){
                            booleanStatus = true ;
                            arrayResponses.push(text);
                        }
                    }

                });

                return {
                    status: booleanStatus,
                    fields: arrayResponses
                };

            },

            async  sendInfo(){
                console.log(this.validadateForm());
               if(!this.validadateForm()){
                   return
                }

                //this.initLoading('Guardando');
                let rateConfigs = this.promotion_info.rates;
                let promotion = {...this.promotion};
                let formatRate = [];
               //console.log('configsNews', promotion, rateConfigs)
                let formData = promotion;
                if(!this.promotion_info.visible.min_nights){
                    formData.min_nights = null;
                    formData.max_nights = null;
                }else if(this.promotion_info.visible.min_nights && !formData.max_nights_status){
                    formData.max_nights  = null;
                }
                if(!this.promotion_info.visible.advance_options){
                    formData.min_cutoff = null;
                    formData.max_cutoff = null;
                }else if(this.promotion_info.visible.advance_options && !formData.max_cutoff_status){
                    formData.max_cutoff = null;
                }
                //delete formData.option_cutoff;
                delete formData.max_nights_status;
                delete formData.max_cutoff_status;
                if(!formData.max_reservations_status){
                    formData.max_reservations = null;
                }
                delete formData.max_reservations_status;

                if(promotion.type==='0'){
                    formData.value_days = [0,0,0,0,0,0,0]
                }else if(promotion.type==='1'){
                    formData.value = null;
                    formData.discount_type = null;
                }else if(promotion.type==='2'){
                    if(promotion.discount_type==='1'){

                        formData.discount_night = null;
                    }
                }
                if(this.promotion_info.visible.advance_options && promotion.option_cutoff==='1'){
                    formData.min_cutoff = 0;
                    formData.max_cutoff = 0;
                }
                if(!this.promotion_info.visible.advance_options){
                    formData.min_cutoff = null;
                    formData.max_cutoff = null;
                }
                if(formData.booking_time!==null && ((this.promotion_info.visible.advance_options && promotion.option_cutoff==='1') || promotion.booking_type===2)){
                    formData.booking_time_from = this.formatConverte(formData.booking_time[0], 'time');
                    formData.booking_time_to = this.formatConverte(formData.booking_time[1], 'time');
                }

                //console.log('formData', formData)
                let aggregated_values = [];

                formData.aggregated_values.forEach((checkAggre) => {
                    if(checkAggre.name!=='' && checkAggre.name !== null){
                        //console.log('checkAggre', checkAggre, checkAggre.name!=='', checkAggre.name!==null)
                        aggregated_values.push(checkAggre)
                    }
                })


                formData.aggregated_values = aggregated_values;
                //console.log('formData', formData)
                formData.rate_configs = [];

                if(!formData.all_rate_configs){
                    let ratesSelected = this.promotion_info.rates;
                    ratesSelected.forEach(roomItem=>{
                        roomItem.rateplans.forEach(rateplanItem=>{
                            if(rateplanItem.status === true){
                                formData.rate_configs.push(rateplanItem.rateconfig_id);
                            }
                            rateplanItem.rateplansLink.forEach(rateLinkItem=>{
                                if(rateLinkItem.status === true){
                                    formData.rate_configs.push(rateLinkItem.rateconfig_id);
                                }
                            })
                        })
                    })
                }
                //console.log('formData', formData)
                if(formData.booking){
                    formData.booking_from = moment(formData.booking[0]).format('YYYY-MM-DD')
                    formData.booking_to = moment(formData.booking[1]).format('YYYY-MM-DD')

                    //console.log('booking', formData.booking)
                }
                let booking_days = [];
                promotion.booking_days.forEach((days_in, idx_in)=>{
                    if(days_in){
                        //idx_in = (idx_in===0)?7:idx_in;
                        booking_days.push(idx_in)
                    }
                });
                //console.log('booking_days',booking_days);
                formData.booking_days = booking_days;

                if(promotion.booking_type===1){



                    formData.booking_from = formData.booking_from+' '+this.formatConverte(formData.booking_from_hour, 'time');
                    formData.booking_to = formData.booking_to+' '+this.formatConverte(formData.booking_to_hour, 'time');
                    formData.booking_days = [0,1,2,3,4,5,6];
                    if(!this.promotion_info.visible.advance_options ||  promotion.option_cutoff==='0'){
                        formData.booking_time_from = '00:00';
                        formData.booking_time_to  = '23:59';
                    }
                }else{
                    formData.booking_from = formData.booking_from+' 00:00';
                    formData.booking_to = formData.booking_to+' 23:59';
                }


                delete formData.booking;
                //delete formData.booking_type;


                formData.travel_dates = formData.travel_selected;
                delete formData.travel_selected;







                //console.log('formData', formData)


                if(this.$route.name==='editPromotion'){
                    await promotionService.updatePromotion(formData).then(async response=>{
                       //console.log('response', response)
                        let responseInfo = response.data.data.promotion;

                        let changes = logFunctions.cheackProp(this.info_page.original_info, responseInfo, this.info_page.section_name)
                        console.log('changes', changes);
                        if(changes.length){
                            await this.createLog(responseInfo._id, 1, changes);
                        }

                        setTimeout(()=>{
                            this.closeLoading();
                        }, 500)
                        this.showSwal(this.$t('promotion.new.msg'));
                    });

                }else{
                    await promotionService.createPromotion(formData).then(async response=>{
                       //console.log('response', response)
                        let responseInfo = response.data.data.promotion;
                        await this.createLog(responseInfo._id, 0)
                        setTimeout(()=>{
                            this.closeLoading();
                        }, 500)
                        this.showSwal(this.$t('promotion.edit.msg'));
                    });
                }



            },
            cancelAction(){
                this.$router.push({
                    name: 'Promotions'
                });
            },
            checkAdvance(page){
                switch (page) {
                    case 'page_2':
                        if(this.info_page.buttons.type){
                            this.info_page.index_page=2;
                        }

                        break;

                    case 'page_3':
                        if(this.info_page.buttons.booking){
                            this.info_page.index_page=3;
                        }

                        break;
                    case 'page_4':
                        if(this.info_page.buttons.travel){
                            this.info_page.index_page=4;
                        }

                        break;

                    case 'page_5':
                        if(this.info_page.buttons.description){
                            this.info_page.index_page=5;
                            this.validadateForm()
                            this.activeErrors =  true;
                        }

                        break;

                }

            },
            stepsValidate(steps){
                let responseStatus = true;

                let arrayAux = [];


                switch (steps) {

                    case 'type':
                       /* */

                        if(this.promotion.type==='0'){
                            arrayAux.push(this.promotion.value)
                            if(this.promotion.max_reservations_status){
                                arrayAux.push(this.promotion.max_reservations)
                            }
                            if(this.validadorCustom(arrayAux, 'number')){
                                responseStatus = false;
                            }
                            if(responseStatus && (this.promotion.value>75 || this.promotion.value < 5) && this.promotion.discount_type === '%'){
                                responseStatus = false;
                            }
                            if(responseStatus && this.promotion.discount_type === ''){
                                responseStatus = false;
                            }
                        }else if(this.promotion.type==='1'){

                            this.promotion.value_days.forEach(itemNum=>{
                                arrayAux.push(itemNum)
                            })
                            if(this.promotion.max_reservations_status){
                                arrayAux.push(this.promotion.max_reservations)
                            }
                            if(this.validadorCustom(arrayAux, 'number')){
                                responseStatus = false;
                            }

                            this.promotion.value_days.forEach(itemNum=>{
                                if(responseStatus && (itemNum>75 || itemNum < 0) ){
                                    responseStatus = false;
                                }
                            })


                        }else if(this.promotion.type==='2'){


                            if(this.promotion.max_reservations_status){
                                arrayAux.push(this.promotion.max_reservations)
                            }


                            if(responseStatus && (this.promotion.discount_type === '' || this.promotion.value==='')){
                                responseStatus = false;
                            }

                            if(responseStatus  && this.promotion.discount_type === '2'){

                                arrayAux.push(this.promotion.discount_night)


                                if(responseStatus && (this.promotion.discount_night>75 || this.promotion.discount_night < 1) ){
                                    responseStatus = false;
                                }

                            }
                            if(this.validadorCustom(arrayAux, 'number')){
                                responseStatus = false;
                            }




                        }






                        //console.log('validadorCustom', arrayAux, this.validadorCustom(arrayAux, 'number'));
                        if(!this.promotion.all_rate_configs){
                            let contStatus = 0;
                            this.promotion_info.rates.forEach(element=>{
                                element.rateplans.forEach(rateplan=>{
                                    if(rateplan.status){
                                        contStatus++;
                                    }
                                    rateplan.rateplansLink.forEach(rateLink=>{
                                        if(rateLink.status){
                                            contStatus++;
                                        }
                                    })
                                })
                            })
                            if(contStatus===0){
                                responseStatus = false;
                            }
                        }
                        this.info_page.buttons.type = responseStatus;
                        break;
                    case 'booking':
                        arrayAux = [this.promotion.booking[0], this.promotion.booking[1], this.promotion.booking_from_hour, this.promotion.booking_to_hour]
                        if(this.promotion.booking_type === 1){
                            if(this.validadorCustom(arrayAux, 'string')){
                                responseStatus = false;
                            }
                        }else{
                            let contStatus = 0;
                            let booking_time = [];
                            if(this.promotion.booking_time === null){
                                booking_time = ['', '']
                            }else{
                                booking_time = this.promotion.booking_time;
                               // console.log(booking_time[0] >= booking_time[1], booking_time[0] , booking_time[1]);
                                if(booking_time[0] >= booking_time[1]){
                                    responseStatus = contStatus++;
                                    responseStatus = false;
                                }
                            }

                            arrayAux = [this.promotion.booking[0], this.promotion.booking[1], booking_time[0], booking_time[1]]

                            this.promotion.booking_days.forEach(item=>{
                                if(item){
                                    contStatus++;
                                }
                            })
                            if(contStatus===0){
                                responseStatus = false;
                            }
                            if(this.validadorCustom(arrayAux, 'string')){
                                responseStatus = false;
                            }
                        }
                        this.info_page.buttons.booking = responseStatus;
                        break;
                    case 'travel':
                        if(this.promotion.travel_selected.length===0){
                            responseStatus = false;
                        }else{
                            //  this.orderDate();
                            let days_selected = [...this.promotion.travel_selected]
                            days_selected.sort()
                            if(moment(days_selected[days_selected.length - 1], 'YYYY-MM-DD') < moment(this.promotion.booking[1], 'YYYY-MM-DD')){
                                responseStatus = false;
                                this.info_page.min_date_travel = '*La última fecha seleccionada no puede ser menor a la fecha final del Booking';
                            }else{
                                this.info_page.min_date_travel = null;
                            }
                        }
                        this.info_page.buttons.travel = responseStatus;
                        break;
                    case 'name':
                        arrayAux.push(this.promotion.name)
                        if(this.validadorCustom(arrayAux, 'string')){
                            responseStatus = false;
                        }
                        this.info_page.buttons.description = responseStatus;
                        break;
                }

            },
            validadorCustom(fields, type){
                let booleanStatus = false;
                fields.forEach(field=>{
                    if(type === 'string'){
                        field = field+'';
                        if(field==='null' ||  field==='' || field.trim().length === 0 ){
                            booleanStatus = true ;
                        }
                    }
                    if(type==='number'){
                        if(isNaN(parseFloat(field)) || !isFinite(field)){
                            booleanStatus = true ;
                        }
                    }
                });

                return booleanStatus;

            },
            checkWeek(){
                let responseStatus = true;
                this.promotion.booking_days.forEach(item =>{
                    if(!item){
                        responseStatus = false;
                    }
                })
                return responseStatus;
            },

            formatConverte(date, formatType = null) {
                let date_return = null;

                switch (formatType) {
                    case 'f-month':

                        date_return  =  moment(date, 'YYYY-MM-DD').format('MMMM D[,] YYYY')
                        break;
                    case 'txt':
                        date_return  =  moment(date, 'YYYY-MM-DD').format('D [de] MMMM YYYY')
                        break;
                    case 'time':
                        date_return  =  moment(date).format('HH:mm')
                        break;

                    default:
                        date_return  =  moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                        break;
                }
                return date_return;
            },
            showSwal (msg) {

                swal({
                    title: this.$t('promotion.new.modal'),
                    text: msg,
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    type: 'success'
                }).then(()=> {
                    this.$router.push({
                        name: 'Promotions'
                    });
                });
            },
            dayName(dayIs){
                let days = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
                return days[dayIs];
            },

            nightDiscount(dayIs){
                let days = ['','primera', 'segunda', 'tercera', 'cuarta', 'quinta', 'sexta', 'séptima'];
                return days[dayIs];
            },
            checkTimeZone(date){
                let gTimeZone = settings.getTimezone();

                let tzdetect =  moment(date)
                    .utc(gTimeZone)

                return tzdetect;

            },
            clearForm(){

                this.promotion.value = '';
                this.promotion.discount_type = '';
                this.stepsValidate('type');
            },
            initLoading(text = 'Cargando...') {
                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            },
            alertError(title_cust, text_cust){


                swal({
                    title: title_cust,
                    type: 'warning',
                    text: text_cust,
                    confirmButtonText: 'Aceptar',
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill'
                })
                /*if(confirm){
                    swal({
                        title: title_cust,
                        text: text_cust,
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonClass: 'btn btn-success btn-fill',
                        cancelButtonClass: 'btn btn-danger btn-fill',
                        confirmButtonText: this.$t('form.yes_deleted'),
                        cancelButtonText: this.$t('form.cancel'),
                        buttonsStyling: false
                    }).then((result)=> {
                        if (result.dismiss !== 'cancel') {
                            console.log('console log');

                        }
                    });
                }else {

                }*/





            },
            isNumberKey(event)
            {
                var charCode = event.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    event.preventDefault();;
                } else {
                    return true;
                }
            },

            toFixed(num, fixed) {

                //num = num.replace(/\D/g,'');
                if(isNaN(parseFloat(num)) || !isFinite(num)){
                    num =  num.replace(/[^\d.-]/g, '')
                }
                var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
                //console.log('re', re);
                return num.toString().match(re)[0];

                //Math.round(floatNumber*100)/100;
            },
        },
        mounted() {
            this.changeProperty()
            //this.$refs.topProgress.start();
        }
    }
</script>
<style lang="scss">
    .bg-transparend-modal {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        z-index: 10;
        background: rgb(2,0,36);
        background: linear-gradient(270deg, rgba(2,0,36,0) 0%, rgba(255,255,255,0.8995973389355743) 150%);
    }
    .minus-aggreates{
        position: relative;
        top: 10px;
        display: inline-block;
        vertical-align: top;
    }

    .box-inventory.promotions {
        .bootstrap-switch {
            position: relative;
            top: 15px;
            float: right;
        }
        .subtitle {
            margin-bottom: 5px;
            font-weight: 600 !important;
            font-size: 16px;
            text-transform: none;
            color: #0e0e0e;

        }

        .form-updates, .form-dates{
            position: relative;
            .resumen-item{
                    label, span{
                        font-size: 14px;
                    }
            }
        }
        span.fllw {
            display: inline-block;
            width: 100%;
        }

        .fw {
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            text-transform: none;
            color: #0e0e0e;
        }
        span.btn-link {
            margin-left: 0;
            cursor: pointer;

        }
        .group-edit {
            position: absolute;
            right: 10px;
            top: 10px;
            .type-text {
                margin-left: 10px;
            }

            .type-button {
                width: 45px;
                height: 45px;
                color: #ffffff;
                text-align: center;
                border-radius: 100px;
                display: inline-block;
                cursor: pointer;
                i {

                    font-size: 25px;
                    line-height: 45px;
                    left: -3px;
                    position: relative;
                }
            }
            .type-text {
                height: 45px;
                line-height: 45px;
                cursor: pointer;
            }
        }
        .group-item {
            &.title{
                label{
                    width: 200px;
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: none;
                    color: #0e0e0e;
                }
            }

            .item-7 {
                width: 10%;
                display: inline-block;
                margin-left: 1%;
                position: relative;
                .fa {
                    position: absolute;
                    top: 37px;
                    bottom: 0;
                    margin: auto;
                    right: 5px;
                }
                span{
                    font-size: 14px;
                }
            }

            .input-default {
                width: 150px;
                display: inline-block;
                color: #0e0e0e;
                margin-bottom: 7px;
            }

            span.btn-link {
                color: #1D62F0;
                display: inline-block;
                font-size: 14px;

            }
            .select-default {
                display: inline-block;
                width: 150px;
                margin-bottom: 7px;
            }
            .large-size{
                width: 200px;

            }

        }

    }

    .line-complete{
        span{
            display: inline-block;
            color: #bf9000;
            font-size: 14px;
            width: auto;
            margin-left: 10px;
        }
        .s-icon {
            font-size: 20px;
            color: #bf9000;
            float: left;
        }
    }
    .box-inventory {
        border: 0;
        .form-send {
            .s-icon {
                font-size: 20px;
                color: #bf9000;
            }
            label {
                display: inline-block;
                color: #bf9000;
                font-size: 14px;
                width: auto;
                margin-left: 10px;
            }
            .box-button {
                button {
                    width: calc(33.3% - 30px);
                    margin-right: 15px;
                }
            }

        }
        .is_title {
            font-size: 16px;
            margin: 0 0 15px;
        }
        .subtitle {
            font-weight: 600;
            margin-bottom: 25px;
        }

        .box-button {
            //padding-left: 10px;
            .add-item {
                width: 100%;
                height: 40px;
                color: #fff;
                background-color: #222222;
                border: 2px solid #000;
                &.inactive {
                    opacity: .2;
                    cursor: default;
                }
            }
        }

        .form-dates {

            .box-dates {
                .form-check {
                    display: inline-block;
                    margin-right: 20px;
                }
            }
            .dates-manually {
                width: 100%;
                .box-calendar {
                    width: 100%;
                }
                .box-legends {
                    width: 100%;
                    padding: 15px 0;
                    display: inline-block;
                    label {
                        width: 30%;
                        float: left;
                        display: inline-block;
                        margin-bottom: 0;
                        &.color-blue {
                            color: #3B4FBF;
                        }
                        &.right-t {
                            text-align: right;
                            width: 40%;
                        }
                        .example-picker {
                            display: inline-block;
                            height: 16px;
                            width: 16px;
                            background: #3B4FBF;
                            margin-right: 7px;
                            border-radius: 15px;
                            &.blocks {
                                background-color: #fff;
                                border: 1px solid;
                            }
                        }
                    }

                }
            }
        }

        .w-border {
            border: 1px solid #bebebe;
            padding: 10px 25px;
            margin-bottom: 15px;
        }
        .form-preview {
            position: relative;
            .l-title {
                font-weight: 600;
            }
            .text-normal {
                font-size: 14px;
            }
            .brl {
                display: inline-block;
                width: 100%;
            }
            .a-right {
                position: absolute;
                right: 50px;
                top: 0;
                bottom: 0;
                left: auto;
                margin: auto;
            }

            .type-button {
                right: 120px;
            }
            .type-text {
                height: 45px;
                line-height: 45px;
            }
            .p-contain {
                width: calc(100% - 40%);
                position: relative;
                display: inline-block;
                border-bottom: 1px solid;
                margin-bottom: 5px;
                .s-left {
                    display: inline-block;
                    width: 50%;
                }
                .s-right {
                    text-align: right;
                    display: inline-block;
                    width: 50%;
                }
            }

        }

        .box-top {
            padding: 25px;
            border: 2px solid #eeeeee;
            margin-bottom: 15px;
        }
        label {
            color: #000;
            width: 100%;
            display: inline-block;
            font-size: 14px;
            text-transform: unset;
            span {
                font-weight: 400;
                margin-left: 5px;
            }
            span.icon-cont {
                margin-left: 0;
            }

        }
        h6 {
            margin-bottom: 15px;
            color: #085394;
            font-size: 18px;
            text-transform: unset;
        }
        .box-calendar {
            .item-month {

                .calendar-title {
                    font-size: 17px;
                    text-align: center;
                    font-weight: 600;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                    //width: 60%;
                    &.w-80 {
                        width: 60%;
                    }
                    &.ml-20 {
                        margin-left: 20%;
                    }
                }
                .sp-icon {
                    width: 20%;
                    text-align: center;
                    &.n-visible {
                        visibility: hidden;
                    }
                }
                display: inline-block;
                width: 30%;
                float: left;
                &:first-child {
                    margin-right: 5%;
                }
                &:last-child {
                    margin-left: 5%;
                }
                .line-week {
                    width: 100%;
                    display: inline-block;
                    &.lw-head {
                        margin-bottom: 0;
                        font-weight: 600;
                    }
                    .item-7 {
                        cursor: pointer;
                        display: inline-block;
                        width: calc(100% / 7);
                        text-align: center;
                        height: 25px;
                        line-height: 25px;
                        float: left;
                        .select-day {
                            background: #3B4FBF;
                            width: 25px;
                            display: inline-block;
                            border-radius: 100px;
                            color: #fff;
                        }


                        .block-day {
                            background: #E1D8E9;
                            width: 100%;
                            display: inline-block;
                            color: #000;
                            cursor: default;
                        }
                    }
                }
            }
        }

        .box-head, .box-rate, .box-restrict {
            width: 100%;
            display: inline-block;
        }
        .box-head {
            margin-top: 15px;
            label {
                font-size: 16px;
                margin-bottom: 25px;
            }
        }
        .box-item {
            display: inline-block;
        }

        .form-check .form-check-label {
            text-transform: none;
        }
    }
    .ordF{
        order: 1;
    }
    .ordL{
        order: 2;
    }
    .mt-3{
        margin-top: 1rem;
    }
    .input-default.has-error input, .el-date-editor.has-error, .input-default.has-error .select-default.el-select .el-input input, .input-default.has-error textarea, .has-error .el-range-editor{
        border-color: #ff4500 !important;
        color: #ff4500!important;;
    }
    label.year-header{
        font-size: 22px;
        font-weight: 600;
    }
</style>
