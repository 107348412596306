import BackendService from './BackendService';

export default class PromotionService extends BackendService {

    constructor() {
        super();
    }

    getPromotions(params) {
        /*let data = [
            {
                _id: '',
                property_id: '5e6013e1734fd90f729d82b9',
                name: 'Promotion 1',
                type: 'Estandar',
                discount: '31%',
                ingresos: '1200',
                total: '12',
                room_nigth: '25',
                travel: ['2020-03-10', '2020-03-25'],
                booking: ['2020-03-10', '2020-03-25'],
                dias_restantes: '12',
                status: true

            },
            {
                _id: '',
                property_id: '5e6013e1734fd90f729d82b9',
                name: 'Promotion 2',
                type: 'Estandar',
                discount: '15%',
                ingresos: '1200',
                total: '12',
                room_nigth: '25',
                travel: ['2020-03-10', '2020-03-25'],
                booking: ['2020-03-10', '2020-03-25'],
                dias_restantes: '12',
                status: true

            },
            {
                _id: '',
                property_id: '5e6013e1734fd90f729d82b9',
                name: 'Promotion 3',
                type: 'Estandar',
                discount: '10%',
                ingresos: '1200',
                total: '12',
                room_nigth: '25',
                travel: ['2020-03-10', '2020-03-25'],
                booking: ['2020-03-10', '2020-03-25'],
                dias_restantes: '12',
                status: true

            },
            {
                _id: '',
                property_id: '5e6013e1734fd90f729d82b9',
                name: 'Promotion 4',
                type: 'Estandar',
                discount: '40%',
                ingresos: '1200',
                total: '12',
                room_nigth: '25',
                travel: ['2020-03-10', '2020-03-25'],
                booking: ['2020-03-10', '2020-03-25'],
                dias_restantes: '12',
                status: false

            },
        ]*/

        return this.httpCall('promotions',{
            params: params,
        });


        //return data.filter(item => item.property_id === params.property_id);





        /*return this.httpCall('promotions',{
            params: params,
        });*/

    }



    getPromotion(promotionId){
        return this.httpCall('promotion/'+promotionId);
    }
    getReservation(reservationId){
        return this.httpCall('reservations',{
            params: {
                id:reservationId
            },
        });
    }
    updatePromotion(item) {
       //console.log('formData', item)
        return this.httpCall('promotion/'+item._id,{
            method: 'PUT',
            data: item,
        });

    }
    createPromotion(item) {
       //console.log('formData', item)
        return this.httpCall('promotion',{
            method: 'POST',
            data: item,
        });

    }





}
